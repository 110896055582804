import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import MainLayout from '../layout/Main.layout';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import SkeletonImage from '../components/SkeletonImage';
import { Helmet } from 'react-helmet-async';
import '../index.css';

const Gallery = () => {
  const [loading, setLoading] = useState(true); // For initial API fetch
  const [pageLoading, setPageLoading] = useState(false); // For pagination changes
  const [images, setImages] = useState([]); // Store images from the API
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const imagesPerPage = 20; // Only display 20 images at a time
  const topRef = useRef(null); // Ref to the top of the gallery

  // Fetch images from the API when the component mounts
  useEffect(() => {
    const cacheExpiration = 10 * 60 * 1000; // 10 minutes
    
    const fetchImages = async () => {
      try {
        const now = new Date().getTime();
        let imagesData;
        const cachedImages = localStorage.getItem("galleryImages");
        
        if (cachedImages) {
          const { data: cachedData, timestamp } = JSON.parse(cachedImages);
          if (now - timestamp < cacheExpiration) {
            imagesData = cachedData;
          }
        }
        
        if (!imagesData) {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/gallery/`);
          imagesData = response.data;
          localStorage.setItem("galleryImages", JSON.stringify({ data: imagesData, timestamp: now }));
        }
        
        setImages(imagesData);
      } catch (error) {
        console.error('Error fetching gallery images:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  // Scroll to the top of the gallery container whenever currentPage changes
  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentPage]);

  // Memoize pagination calculations to prevent unnecessary recalculations
  const paginationData = useMemo(() => {
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);
    const totalPages = Math.ceil(images.length / imagesPerPage);
    
    return {
      currentImages,
      totalPages,
      indexOfFirstImage,
      indexOfLastImage
    };
  }, [images, currentPage, imagesPerPage]);

  // Handle pagination with a loading state for page transitions
  const handlePageChange = (newPage) => {
    setPageLoading(true);
    // Remove current images immediately by showing a loader
    // Simulate a delay (adjust or remove the delay as needed)
    setTimeout(() => {
      setCurrentPage(newPage);
      setPageLoading(false);
    }, 500);
  };

  const nextPage = () => {
    if (currentPage < paginationData.totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  // Memoize the gallery items to prevent unnecessary re-renders
  const galleryItems = useMemo(() => {
    if (loading || pageLoading) {
      return [...Array(6)].map((_, index) => (
        <SkeletonImage key={index} height={'9rem'} width={'10rem'} />
      ));
    }
    
    return paginationData.currentImages.map((item, index) => (
      <PhotoView key={item.id} src={item.image}>
        <img
          src={item.image}
          alt={`Gallery item ${index + 1}`}
          className="md:w-80 w-40 md:h-40 h-36 rounded overflow-hidden object-cover transition-transform transform hover:scale-105 duration-500 ease-in-out"
          loading="lazy"
        />
      </PhotoView>
    ));
  }, [loading, pageLoading, paginationData.currentImages]);

  return (
    <div ref={topRef}>
      <Helmet>
        <title>Gallery | DFIIE</title>
      </Helmet>
      <div className="md:px-8 px-4 py-2">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#192f59] text-center mb-4 sm:mb-6 md:mb-8 relative pb-8">
            <span className="relative inline-block">
              Gallery
              <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-1/2 h-1 bg-blue-500 rounded-full "></span>
            </span>
          </h2>
        <PhotoProvider>
          <div className="flex justify-center items-center flex-wrap gap-4 py-4">
            {galleryItems}
          </div>
        </PhotoProvider>
        {/* Pagination Controls */}
        {!loading && paginationData.totalPages > 1 && (
          <div className="flex justify-center items-center mt-4 space-x-4">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded ${
                currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'
              }`}
            >
              Previous
            </button>
            <span className="text-lg font-medium">
              Page {currentPage} of {paginationData.totalPages}
            </span>
            <button
              onClick={nextPage}
              disabled={currentPage === paginationData.totalPages}
              className={`px-4 py-2 rounded ${
                currentPage === paginationData.totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'
              }`}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainLayout(Gallery);
