import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const PageNotFound = () => {
  useEffect(() => {
    // Add animation classes after component mounts
    const timer = setTimeout(() => {
      document.querySelector('.luxury-error-container').classList.add('animated');
      
      // Staggered animation for elements
      const elements = document.querySelectorAll('.stagger-animate');
      elements.forEach((el, index) => {
        setTimeout(() => {
          el.classList.add('animated');
        }, 300 + (index * 150));
      });
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center relative overflow-hidden">
      {/* Premium background with subtle patterns */}
      <div className="absolute inset-0 bg-gradient-to-br from-gray-50 via-blue-50 to-indigo-50">
        <div className="absolute inset-0 opacity-20" style={{ 
          backgroundImage: `radial-gradient(circle at 25px 25px, rgba(79, 70, 229, 0.08) 2%, transparent 0%), 
                           radial-gradient(circle at 75px 75px, rgba(79, 70, 229, 0.08) 2%, transparent 0%)`,
          backgroundSize: '100px 100px' 
        }}></div>
        
        {/* Floating orbs in background */}
        <div className="orb orb-1"></div>
        <div className="orb orb-2"></div>
        <div className="orb orb-3"></div>
      </div>
      
      <div className="luxury-error-container relative z-10 w-full max-w-3xl opacity-0 transform translate-y-8 transition-all duration-1000 ease-out mx-4">
        <div className="glass-card backdrop-filter backdrop-blur-xl bg-white bg-opacity-70 rounded-3xl shadow-xl overflow-hidden border border-white">
          {/* Accent line */}
          <div className="h-1.5 w-full bg-gradient-to-r from-indigo-400 via-blue-500 to-indigo-400"></div>
          
          <div className="relative p-6 md:p-12">
            {/* 404 with 3D treatment */}
            <div className="flex justify-center mb-8 md:mb-12 relative stagger-animate opacity-0 transform -translate-y-4">
              <div className="error-number-3d relative inline-block">
                <span className="error-number-text text-7xl sm:text-8xl md:text-9xl font-black tracking-tighter text-transparent bg-clip-text bg-gradient-to-br from-indigo-600 via-blue-600 to-indigo-700">404</span>
                <span className="error-number-shadow text-7xl sm:text-8xl md:text-9xl font-black tracking-tighter text-transparent absolute top-0.5 left-0.5 z-0 opacity-20 blur-sm bg-clip-text bg-gradient-to-br from-indigo-400 to-blue-500">404</span>
              </div>
            </div>
            
            {/* Error illustration */}
            <div className="flex justify-center mb-8 md:mb-10 stagger-animate opacity-0 transform -translate-y-4">
              <div className="error-illustration relative w-40 h-40 sm:w-48 sm:h-48 md:w-60 md:h-60">
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg viewBox="0 0 200 200" className="w-full h-full" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient id="errorGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0%" stopColor="#818cf8" />
                        <stop offset="100%" stopColor="#4f46e5" />
                      </linearGradient>
                    </defs>
                    <path fill="url(#errorGradient)" d="M48.4,-64.5C60.9,-53.9,68,-36.8,73.4,-19.2C78.9,-1.6,82.6,16.5,76.8,30.6C71,44.8,55.5,55.1,39.6,62.1C23.6,69.1,7.2,72.9,-10.7,73.6C-28.7,74.3,-48.4,71.9,-63.6,61C-78.8,50.1,-89.7,30.7,-90.7,10.8C-91.7,-9.1,-82.8,-29.5,-69.3,-43.6C-55.8,-57.6,-37.6,-65.2,-20.5,-69.4C-3.4,-73.6,12.7,-74.5,28.5,-72.2C44.3,-69.9,59.9,-64.4,48.4,-64.5Z" transform="translate(100 100)" />
                  </svg>
                </div>
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 text-white opacity-90" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="12"></line>
                    <line x1="12" y1="16" x2="12.01" y2="16"></line>
                  </svg>
                </div>
              </div>
            </div>
            
            {/* Message */}
            <div className="text-center mb-8 md:mb-12 stagger-animate opacity-0 transform -translate-y-4">
              <h2 className="text-2xl sm:text-3xl md:text-4xl font-extrabold mb-3 md:mb-4 tracking-tight text-gray-800">Page Not Found</h2>
              <p className="text-gray-600 text-base sm:text-lg max-w-lg mx-auto leading-relaxed">
                The page you're looking for has vanished into the digital ether. Perhaps it was moved or never existed.
              </p>
            </div>
            
            {/* Button Group */}
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-5 justify-center stagger-animate opacity-0 transform -translate-y-4">
              <Link
                to="/"
                className="premium-button home-button group"
              >
                <span className="btn-bg absolute inset-0 bg-gradient-to-br from-indigo-500 via-blue-500 to-indigo-600 rounded-xl opacity-90 group-hover:opacity-100 transition-all duration-300 group-hover:scale-105 shadow-lg"></span>
                <span className="btn-content relative flex items-center justify-center gap-2 text-white font-medium py-3 sm:py-4 px-6 sm:px-8">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                  Return Home
                </span>
              </Link>
              
              <button
                onClick={() => window.history.back()}
                className="premium-button back-button group"
              >
                <span className="btn-bg absolute inset-0 bg-gray-100 border border-gray-200 rounded-xl group-hover:bg-gray-200 transition-all duration-300 group-hover:scale-105 shadow-lg"></span>
                <span className="btn-content relative flex items-center justify-center gap-2 text-gray-700 font-medium py-3 sm:py-4 px-6 sm:px-8">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
                  </svg>
                  Go Back
                </span>
              </button>
            </div>
          </div>
        </div>
        
        {/* Accent design elements */}
        <div className="absolute -top-20 -right-20 w-40 h-40 bg-gradient-to-br from-blue-400 to-indigo-400 rounded-full blur-3xl opacity-20"></div>
        <div className="absolute -bottom-20 -left-20 w-40 h-40 bg-gradient-to-br from-indigo-400 to-violet-400 rounded-full blur-3xl opacity-20"></div>
      </div>
    </div>
  );
};

export default PageNotFound; 