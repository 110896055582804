import React from "react"; // Import React
import { Vcmessageforhomee, directormessageforhomee } from "../../Data/Homevc"; // Import messages for VC and Director
import { Fade } from "react-awesome-reveal"; // Import Fade animation for transitions
import directorImg from '../images/dircterpic.jpg'; // Import Director's image
import vcImage from '../images/vcpic.jpg'; // Import VC's image
import { Link } from "react-router-dom"; // Import Link for navigation

const Vcmessageforhome = () => {
  return (
    <>
      <div className="bg-white py-8 md:py-12">
        <div className="container mx-auto">
          {/* <h2 className="text-3xl sm:text-4xl md:text-5xl text-center font-bold text-[#192f59] mb-8 md:mb-12">Leadership Insights</h2> */}
          
          <div className="flex flex-col md:flex-row md:items-stretch gap-6 sm:gap-8 md:gap-12 px-3 sm:px-4 md:px-8">
            {/* VC Message Section */}
            <div className="w-full md:w-1/2 bg-gradient-to-b from-white to-gray-50 rounded-xl sm:rounded-2xl shadow-lg overflow-hidden">
              <div className="p-4 sm:p-6 md:p-8 flex flex-col items-center">
                {/* VC Image with fade effect */}
                <Fade direction='left' triggerOnce={true} className="mb-4 sm:mb-6">
                  <div className="w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 rounded-full overflow-hidden border-4 border-blue-100 shadow-md">
                    <img
                      src={vcImage}
                      className="h-full w-full object-cover"
                      alt="Vice Chancellor Colonel Prof. (Dr.) K. V. Kale"
                      loading="lazy"
                    />
                  </div>
                </Fade>
                
                {/* VC Message with fade effect */}
                <Fade direction='up' triggerOnce={true} className="w-full">
                  <div className="flex flex-col gap-3 sm:gap-4">
                    <h3 className="text-xl sm:text-2xl md:text-3xl font-semibold text-[#192f59] border-b-2 border-blue-200 pb-2">
                      From the Desk of the Hon'ble Chairman
                    </h3>
                    
                    <div className="prose prose-xs sm:prose-sm md:prose-base text-gray-700">
                      {Vcmessageforhomee.map((data, index) => (
                        <p key={index} className="text-justify text-[10px] md:text-base leading-relaxed">
                          {data}
                          {index === Vcmessageforhomee.length - 1 && (
                            <Link to={'/about/vcMessage'} className="inline-flex items-center ml-1 text-blue-600 hover:text-blue-800 font-medium transition-colors duration-200 text-[10px] sm:text-sm md:text-base">
                              Read More
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 sm:h-4 sm:w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                              </svg>
                            </Link>
                          )}
                        </p>
                      ))}
                    </div>
                    
                    <p className="text-sm sm:text-lg md:text-xl font-bold text-blue-700 mt-2 text-end">
                      - Colonel Prof. (Dr.) K. V. Kale
                    </p>
                  </div>
                </Fade>
              </div>
            </div>

            {/* Director Message Section */}
            <div className="w-full md:w-1/2 bg-gradient-to-b from-white to-gray-50 rounded-xl sm:rounded-2xl shadow-lg overflow-hidden mt-6 sm:mt-8 md:mt-0">
              <div className="p-4 sm:p-6 md:p-8 flex flex-col items-center">
                {/* Director Image with fade effect */}
                <Fade direction='right' triggerOnce={true} className="mb-4 sm:mb-6">
                  <div className="w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 rounded-full overflow-hidden border-4 border-blue-100 shadow-md">
                    <img
                      src={directorImg}
                      className="h-full w-full object-cover"
                      alt="Director Prof. S. L. Nalbalwar"
                      loading="lazy"
                    />
                  </div>
                </Fade>
                
                {/* Director Message with fade effect */}
                <Fade direction='up' triggerOnce={true} className="w-full">
                  <div className="flex flex-col gap-3 sm:gap-4">
                    <h3 className="text-xl sm:text-2xl md:text-3xl font-semibold text-[#192f59] border-b-2 border-blue-200 pb-2">
                      From the Desk of the Director
                    </h3>
                    
                    <div className="prose prose-xs sm:prose-sm md:prose-base text-gray-700">
                      {directormessageforhomee.map((data, index) => (
                        <p key={index} className="text-justify text-[10px] md:text-base leading-relaxed">
                          {data}
                          {index === directormessageforhomee.length - 1 && (
                            <Link to={'/about/directormessage'} className="inline-flex items-center ml-1 text-blue-600 hover:text-blue-800 font-medium transition-colors duration-200 text-[10px] sm:text-sm md:text-base">
                              Read More
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 sm:h-4 sm:w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                              </svg>
                            </Link>
                          )}
                        </p>
                      ))}
                    </div>
                    
                    <p className="text-sm sm:text-lg md:text-xl font-bold text-blue-700 mt-2 text-end">
                      - Prof. S. L. Nalbalwar
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vcmessageforhome;
