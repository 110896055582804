// ImageService.js
// A service to load and cache images and other API data

// Default API base URL for development fallback if environment variable is missing
const DEFAULT_API_BASE = 'https://api.example.com';

// Fallback image URLs for development when API endpoints are unavailable
const FALLBACK_IMAGES = {
  banners: [
    'https://placehold.co/1200x400?text=Banner+Image+1',
    'https://placehold.co/1200x400?text=Banner+Image+2'
  ],
  partners: [
    'https://placehold.co/200x200?text=Partner+1',
    'https://placehold.co/200x200?text=Partner+2' 
  ],
  upcomingEvents: [
    'https://placehold.co/600x400?text=Event+1',
    'https://placehold.co/600x400?text=Event+2'
  ],
  news: [
    'https://placehold.co/800x400?text=News+1',
    'https://placehold.co/800x400?text=News+2'
  ]
};

// Get the base URL from environment variables or use default
const API_BASE = process.env.REACT_APP_API_BASE_URL || DEFAULT_API_BASE;

// API endpoints
const API_ENDPOINTS = {
  banners: `${API_BASE}/api/home-banners/`,
  upcomingEvents: `${API_BASE}/api/upcoming-events/`,
  partners: `${API_BASE}/api/value-partners/`,
  news: `${API_BASE}/api/newsletters/`,
};

// Event handler for loading progress updates
let progressCallback = null;

class ImageService {
  /**
   * Set callback for progress updates
   * @param {Function} callback - Function to call with progress updates (0-100)
   */
  static setProgressCallback(callback) {
    progressCallback = callback;
  }

  /**
   * Update loading progress
   * @param {number} percent - Progress percentage (0-100)
   */
  static updateProgress(percent) {
    const safePercent = Math.min(100, Math.max(0, Math.round(percent)));
    
    if (progressCallback) {
      try {
        progressCallback(safePercent);
      } catch (error) {
        // Error in progress callback
      }
    }
  }

  /**
   * Fetch data from a specific API endpoint
   * @param {string} endpoint - API endpoint URL
   * @param {string} name - Name of the API for logging
   * @returns {Promise<Object>} The API response data
   */
  static async fetchApiData(endpoint, name) {
    try {
      // Check if API base URL is defined
      if (!process.env.REACT_APP_API_BASE_URL) {
        // API_BASE_URL is not defined
      }
      
      const response = await fetch(endpoint);
      
      if (!response.ok) {
        if (response.status === 404) {
          // API endpoint not found (404)
        } else {
          // Failed to fetch from API
        }
        return [];
      }
      
      const data = await response.json();
      return data;
    } catch (error) {
      // Error fetching from API
      return [];
    }
  }

  /**
   * Extract image URLs from API data
   * @param {Object} data - API response data
   * @returns {string[]} Array of image URLs
   */
  static extractImageUrls(data) {
    if (!data) return [];
    
    const urls = [];
    
    if (Array.isArray(data)) {
      // Handle array data
      data.forEach(item => {
        if (item && typeof item === 'object') {
          // Look for image fields
          ['image', 'banner_image', 'thumbnail', 'photo', 'logo'].forEach(field => {
            if (item[field] && typeof item[field] === 'string') {
              urls.push(item[field]);
            }
          });
        }
      });
    } else if (typeof data === 'object') {
      // Handle object data
      Object.values(data).forEach(value => {
        if (typeof value === 'string' && 
            (value.includes('.jpg') || 
             value.includes('.png') || 
             value.includes('.jpeg') || 
             value.includes('.gif'))) {
          urls.push(value);
        }
      });
    }
    
    return urls;
  }

  /**
   * Fetch image URLs from all API endpoints
   * @returns {Promise<string[]>} Array of all image URLs
   */
  static async fetchAllImageUrls() {
    try {
      const apiEntries = Object.entries(API_ENDPOINTS);
      const totalApis = apiEntries.length;
      let completedApis = 0;
      let allImageUrls = [];
      
      // Update progress at the start
      this.updateProgress(0);
      
      // Check if API base URL is configured
      if (!process.env.REACT_APP_API_BASE_URL) {
        // REACT_APP_API_BASE_URL is not configured
      }
      
      // Fetch data from each API endpoint
      for (const [name, url] of apiEntries) {
        try {
          const data = await this.fetchApiData(url, name);
          const imageUrls = this.extractImageUrls(data);
          
          // If we got no images and we're in development, use fallbacks
          if (imageUrls.length === 0 && process.env.NODE_ENV === 'development') {
            const fallbacks = FALLBACK_IMAGES[name] || [];
            
            if (fallbacks.length > 0) {
              fallbacks.forEach(fallbackUrl => {
                if (!allImageUrls.includes(fallbackUrl)) {
                  allImageUrls.push(fallbackUrl);
                }
              });
            }
          } else {
            // Add unique URLs to our collection
            imageUrls.forEach(url => {
              if (!allImageUrls.includes(url)) {
                allImageUrls.push(url);
              }
            });
          }
        } catch (endpointError) {
          // Failed to process endpoint
          
          // Use fallbacks in development mode
          if (process.env.NODE_ENV === 'development') {
            const fallbacks = FALLBACK_IMAGES[name] || [];
            
            if (fallbacks.length > 0) {
              fallbacks.forEach(fallbackUrl => {
                if (!allImageUrls.includes(fallbackUrl)) {
                  allImageUrls.push(fallbackUrl);
                }
              });
            }
          }
        } finally {
          // Update progress after each API is processed, even if it failed
          completedApis++;
          const apiProgress = Math.round((completedApis / totalApis) * 40);
          this.updateProgress(apiProgress);
        }
      }
      
      return allImageUrls;
    } catch (error) {
      // Last resort fallback - combine all fallback images if in development
      if (process.env.NODE_ENV === 'development') {
        const allFallbacks = [];
        
        Object.values(FALLBACK_IMAGES).forEach(categoryImages => {
          categoryImages.forEach(url => {
            if (!allFallbacks.includes(url)) {
              allFallbacks.push(url);
            }
          });
        });
        
        return allFallbacks;
      }
      
      return [];
    }
  }

  /**
   * Preload all images by creating Image objects
   * @param {string[]} urls - Array of image URLs to preload
   * @returns {Promise<boolean>} - Resolves to true when all images are loaded
   */
  static preloadImages(urls) {
    if (!urls || urls.length === 0) {
      this.updateProgress(100);
      return Promise.resolve(true);
    }

    let loadedCount = 0;
    const totalCount = urls.length;

    const promises = urls.map(url => {
      return new Promise(resolve => {
        const img = new Image();
        
        img.onload = () => {
          loadedCount++;
          
          // Calculate and report progress (images are 60% of total progress, starting from 40%)
          const imageProgress = Math.round((loadedCount / totalCount) * 60);
          this.updateProgress(40 + imageProgress);
          
          resolve(url);
        };
        
        img.onerror = () => {
          loadedCount++;
          const imageProgress = Math.round((loadedCount / totalCount) * 60);
          this.updateProgress(40 + imageProgress);
          resolve(null);
        };
        
        img.src = url;
      });
    });

    return Promise.all(promises)
      .then(() => {
        this.updateProgress(100);
        return true;
      })
      .catch(error => {
        // Error preloading images
        this.updateProgress(100);
        return false;
      });
  }

  /**
   * Fetch and preload all resources
   * @returns {Promise<boolean>} - Resolves when all resources are loaded
   */
  static async loadAllImages() {
    try {
      // Step 1: Fetch data from all APIs and extract image URLs
      const urls = await this.fetchAllImageUrls();
      
      // Step 2: Preload all images
      return await this.preloadImages(urls);
    } catch (error) {
      // Error in loadAllImages
      this.updateProgress(100);
      return false;
    }
  }
}

export default ImageService;