import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import axios from "axios";
import loadingImg from "../images/Spin.gif";

export default function NewsLetter() {
  const [newss, setNewss] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNewsletters = async () => {
      const cacheExpiration = 10 * 60 * 1000; // 10 minutes
      const now = new Date().getTime();
      const cached = localStorage.getItem("newsletters");

      if (cached) {
        const { data, timestamp } = JSON.parse(cached);
        if (now - timestamp < cacheExpiration) {
          setNewss(data);
          setLoading(false);
          return;
        } else {
          localStorage.removeItem("newsletters");
        }
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/newsletters/`
        );
        setNewss(response.data);
        localStorage.setItem(
          "newsletters",
          JSON.stringify({ data: response.data, timestamp: now })
        );
      } catch (error) {
        console.error("Error fetching newsletters:", error);
        setError(
          <div className="text-center">
            <img src={loadingImg} alt="No events" className="mx-auto mb-4 h-20" />
          </div>
        );
      } finally {
        setLoading(false);
      }
    };

    fetchNewsletters();
  }, []);

  // Memoize the newsletter slides to prevent unnecessary re-renders
  const newsletterSlides = useMemo(() => {
    return newss.map((news, index) => (
      <SwiperSlide
        key={news.id || index}
        className="slider-slide flex justify-center items-center overflow-hidden"
      >
        <Link to={`/news&announcement`}>
          <div className="slide-content w-full h-full flex justify-center items-center overflow-hidden">
            <img
              src={news.image}
              alt={news.title || `newsletter-${index + 1}`}
              loading="lazy"
              className="slide-image h-full w-72 object-contain transition-transform duration-300 ease-in-out rounded-md"
            />
          </div>
        </Link>
      </SwiperSlide>
    ));
  }, [newss]);

  return (
    <>
      <style>
        {`
          .swiper-slide-active {
            transform: scale(1.1);
            z-index: 3;
            opacity: 1;
          }
          .mySwiper .swiper-slide-prev,
          .mySwiper .swiper-slide-next {
            transform: scale(0.8);
            z-index: 2;
            opacity: 0.7;
          }
          .swiper-slide {
            transition: transform 0.4s ease, opacity 0.4s ease;
          }
          @media (max-width: 1000px) {
            .swiper-slide-active {
              transform: scale(1.05);
            }
            .mySwiper .swiper-slide-prev,
            .mySwiper .swiper-slide-next {
              transform: scale(0.7);
              opacity: 0.7;
            }
          }
        `}
      </style>

      <Fade direction="up" triggerOnce={true} className="bg-white">
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#192f59] text-center pb-4 sm:pb-6 md:pb-8 relative">
            <span className="relative inline-block">
              Newsletters
              <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-1/2 h-1 bg-blue-500 rounded-full"></span>
            </span>
          </h2>
        <div className="py-6 flex justify-center items-center">
          {loading ? (
            <div className="text-center">
              <img
                src={loadingImg}
                alt="Loading..."
                className="mx-auto pb-4 h-20"
              />
            </div>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : (
            <Swiper
              slidesPerView={3}
              spaceBetween={2.5}
              loop={true}
              centeredSlides={true}
              autoplay={{ delay: 2000, disableOnInteraction: false }}
              navigation={true}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper w-full max-w-[85rem] h-[43.75rem] mx-auto"
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 2.5,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 2,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 7.5,
                },
              }}
            >
              {newsletterSlides}
            </Swiper>
          )}
          
        </div>
          <div className="text-center pt-4">
            <Link 
              to="/news&announcement" 
              className="inline-flex items-center px-4 sm:px-5 md:px-6 py-2 sm:py-2.5 md:py-3 bg-[#192f59] text-white text-xs sm:text-sm md:text-base font-medium rounded-md sm:rounded-lg transition-all duration-300 hover:bg-blue-700 hover:shadow-lg"
            >
              View All Newsletters
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5 ml-1.5 sm:ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </Link>
          </div>
      </Fade>
    </>
  );
}
