import React, { useEffect, useState } from 'react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'
import logo from './images/logo2.png'
import { Link, useLocation } from 'react-router-dom'
import './style.css'
import { elements } from '../Data/NavbarData'

// Navbar for small screens
export const NavbarSm = ({ onClose, setNavbarVisible }) => {
    const [visible, setVisible] = useState(-1) // State to track which dropdown is visible

    // Toggles the visibility of dropdown items
    const handleVisible = (idx) => {
        setVisible(visible === idx ? -1 : idx)
    }

    return (
        <>
            <div className='flex items-center justify-between px-2 py-1.5 bg-white'>
                <div className='flex items-center gap-2'>
                    <div>
                        <img src={logo} alt='Logo' className='md:h-32 sm:h-24 w-auto h-16' />
                    </div>
                    <div className='sm:flex flex-col hidden'>
                        <h1 className='lg:text-2xl md:text-xl text-lg font-semibold text-blue-800 font-serif'>
                            DBATU Forum Of Innovation, Incubation & Enterprise (DFIFE)
                        </h1>
                        <h6 className='lg:text-lg text-sm font-semibold'>
                            Dr. Babasaheb Ambedkar Technological University (DBATU), Lonere, Maharashtra - 402103
                        </h6>
                    </div>
                </div>
                <div>
                    <button onClick={onClose}> {/* Close button for the navbar */}
                        <AiOutlineClose size={30} />
                    </button>
                </div>
            </div>
            <div className='flex flex-col gap-2.5 py-2 px-2'>
                {elements.map((element, idx) => ( // Map through navigation elements
                    <div className='relative cursor-pointer px-2' key={element.name}>
                        <div className='flex gap-1 z-20 items-center justify-between text-lg font-medium' onClick={() => handleVisible(idx)}>
                            <Link to={element?.path}>{element.name}</Link> {/* Link to the main nav item */}
                            {visible === idx ? <BiChevronUp size={24} /> : element.items && <BiChevronDown size={24} />}
                        </div>
                        {element.items && ( // Conditional rendering for dropdown items
                            <div className={`flex flex-col items-start justify-center gap-2 w-full height-transition ${visible === idx ? 'h-auto overflow-visible' : 'h-0 overflow-hidden'}`}>
                                {element.items.map((ele) => ( // Map through dropdown items
                                    <Link to={ele.path} className='w-full p-1.5 font-medium' key={ele.name} onClick={() => setNavbarVisible(false)}>
                                        <span>{ele.name}</span>
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    )
}

// Main Navbar component
const Navbar = () => {
    const [visible, setVisible] = useState(-1) // State to track hovered dropdown
    const { pathname } = useLocation() // Get current pathname
    const [active, setActive] = useState("") // State for active link

    // Effect to determine the active link based on pathname
    useEffect(() => {
        let i = 1
        let currActive = ""
        while (pathname.charAt(i) !== '/' && i < pathname.length) {
            currActive += pathname.charAt(i)
            i++
        }
        // Set active link based on the current path
        setActive(currActive.length === 0 ? "home" : currActive === 'news&announcement' ? 'news & announcement' : currActive)
    }, [pathname])

    return (
        <nav className='lg:flex sticky z-20 top-0 bg-[#005580] hidden items-center justify-center gap-4 py-3 shadow-md'>
            {elements.map((element, idx) => ( // Map through navigation elements
                <div 
                    className='relative cursor-pointer px-3' 
                    onMouseOver={() => setVisible(idx)} 
                    onMouseOut={() => setVisible(-1)} 
                    key={element.name}
                >
                    <div className={`flex gap-1.5 items-center text-lg z-20 font-medium transition-all duration-300 border-b-2 ${active === element.name.toLowerCase() ? 'border-[#b0e7ff] text-[#b0e7ff]' : 'border-transparent text-white'} hover:border-[#b0e7ff] hover:text-[#b0e7ff] px-1 py-1`}>
                        <Link to={element?.path} className="tracking-wide">{element.name}</Link> {/* Link to the main nav item */}
                        {element?.items && <BiChevronDown className="transition-transform duration-300 group-hover:rotate-180" size={20} />} {/* Show dropdown indicator if there are items */}
                    </div>
                    {element.items && visible === idx && ( // Conditional rendering for dropdown items on hover
                        <div 
                            className='flex flex-col items-start justify-center w-52 absolute z-10 bg-white rounded-b-lg shadow-xl top-[40px] overflow-hidden transition-all duration-300 animate-fadeIn' 
                            onMouseOver={() => setVisible(idx)} 
                            onMouseOut={() => setVisible(-1)}
                        >
                            {element.items.map((ele) => ( // Map through dropdown items
                                <Link 
                                    to={ele.path} 
                                    className='w-full p-2.5 text-gray-700 hover:bg-blue-50 hover:text-blue-700 transition-colors duration-200 font-medium border-l-2 border-transparent hover:border-blue-500' 
                                    key={ele.name}
                                >
                                    <span className="ml-1">{ele.name}</span>
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </nav>
    )
}

export default Navbar
