import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import loadingImg from "../images/Spin.gif";
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';

const AboutUsAndEvents = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [aboutUs, setAboutUs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cacheExpiration = 10 * 60 * 1000; // 10 minutes
    
    const fetchData = async () => {
      try {
        const now = new Date().getTime();
        let eventsData, aboutUsData;
        
        // Check localStorage for cached data with timestamps
        const cachedEvents = localStorage.getItem("upcomingEvents");
        const cachedAboutUs = localStorage.getItem("aboutUs");
        
        if (cachedEvents) {
          const { data: cachedEventsData, timestamp } = JSON.parse(cachedEvents);
          if (now - timestamp < cacheExpiration) {
            eventsData = cachedEventsData;
          }
        }
        
        if (cachedAboutUs) {
          const { data: cachedAboutUsData, timestamp } = JSON.parse(cachedAboutUs);
          if (now - timestamp < cacheExpiration) {
            aboutUsData = cachedAboutUsData;
          }
        }
        
        // Fetch only what we need
        if (!eventsData) {
          const eventsResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/events/`);
          eventsData = eventsResponse.data;
          localStorage.setItem("upcomingEvents", JSON.stringify({ 
            data: eventsData, 
            timestamp: now 
          }));
        }
        
        if (!aboutUsData) {
          const aboutResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/aboutus/`);
          aboutUsData = aboutResponse.data[0];
          localStorage.setItem("aboutUs", JSON.stringify({ 
            data: aboutUsData, 
            timestamp: now 
          }));
        }
        
        setUpcomingEvents(eventsData);
        setAboutUs(aboutUsData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(
          <div className="text-center">
            <img src={loadingImg} alt="No events" className="mx-auto mb-4 h-20" loading="lazy" />
          </div>
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Memoize the events slides to prevent unnecessary re-renders
  const eventSlides = useMemo(() => {
    if (upcomingEvents.length === 0) {
      return (
        <div className="text-center">
          <img src={loadingImg} alt="Loading..." className="mx-auto mb-4 h-20" loading="lazy" />
        </div>
      );
    }
    
    return upcomingEvents
  .slice()
  .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date (earliest first)
  .slice(0, 3) // Take only the first 3 events
  .map((event) => {
    const eventDate = new Date(event.date).toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    return (
      <SwiperSlide key={event.id}>
        <Link to="/events">
          <div className="relative flex items-center justify-center">
            <img
              className="w-auto max-w-full max-h-80 sm:h-40 md:h-60 lg:h-80 xl:h-80 rounded-lg event-image m-4"
              src={event.image}
              alt="Event"
              loading="lazy"
            />
          </div>
        </Link>
        <div className="h-auto w-full flex-grow">
          <p className="text-gray-900 text-lg font-semibold mb-2 text-left">{event.title}</p>
          <p className="text-xs md:text-sm text-gray-500 mb-2 text-justify">{event.description}</p>
          <p className="text-sm text-blue-600 font-semibold text-left">{eventDate}</p>
        </div>
      </SwiperSlide>
    );
  });
  }, [upcomingEvents]);

  // Memoize the about us content
  const aboutUsContent = useMemo(() => {
    if (loading) {
      return (
        <div className="text-center">
          <img src={loadingImg} alt="Loading..." className="mx-auto mb-4 h-20" loading="lazy" />
        </div>
      );
    }
    
    if (error) {
      return <p className="text-center text-red-500">{error}</p>;
    }
    
    if (aboutUs) {
      return (
        <p className="text-xs md:text-sm lg:text-base text-gray-700 mb-6 text-justify px-4 lg:px-[2.5rem] 3xl:text-xl ">
          {aboutUs.about || "No information available."}
        </p>
      );
    }
    
    return (
      <div className="text-center">
        <img src={loadingImg} alt="Loading..." className="mx-auto mb-4" loading="lazy" />
      </div>
    );
  }, [loading, error, aboutUs]);

  return (
    <>
      <div className="w-full h-auto flex flex-col lg:flex-row justify-between items-start lg:p-10">
        <style>
          {`
          @keyframes blink {
            0%, 100% { opacity: 1; }
            50% { opacity: 0.5; }
          }
          .blink {
            animation: blink 1s infinite;
          }
          .event-image {
            border-radius: 12px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
            transition: transform 0.3s ease;
          }
          .event-image:hover {
            transform: scale(1.05);
          }
          @keyframes shadow-blink {
            0%, 100% { box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); }
            50% { box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5); }
          }
          .shadow-blink {
            animation: shadow-blink 1s infinite;
          }
          .hover-shadow {
            transition: box-shadow 0.3s ease;
          }
          .hover-shadow:hover {
            box-shadow: 0 0 20px rgba(37, 99, 235, 0.5);
          }
          .hover-shadow-mission:hover {
            box-shadow: 0 0 20px rgba(34, 197, 94, 0.5);
          }
          `}
        </style>

        {/* About Us Section */}
        <div className="w-full lg:w-[60%] flex flex-col mb-8 lg:mb-0">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#192f59] text-center mb-4 sm:mb-6 md:mb-8 relative">
            <span className="relative inline-block">
              About Us
              <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-1/2 h-1 bg-blue-500 rounded-full"></span>
            </span>
          </h2>
          {aboutUsContent}
          <div className="flex flex-col lg:flex-row justify-between items-stretch gap-6 lg:gap-8 p-4 lg:px-[2rem] mt-4">
            {/* Vision Section */}
            <div className="p-6 border-2 border-blue-500 rounded-xl shadow-lg bg-white w-full lg:w-1/2 hover-shadow transition-all duration-300 flex flex-col">
              <h3 className="text-xl lg:text-2xl mb-4 text-center text-black-700 2xl:text-3xl pb-2 border-b border-blue-200">
                
                  Vision
                
              </h3>
              <div className="flex-grow flex items-center">
                {aboutUs?.vision ? (
                  <p className="text-sm md:text-base lg:text-base 3xl:text-lg text-gray-700 leading-relaxed">
                    {aboutUs.vision}
                  </p>
                ) : (
                  <div className="text-center w-full">
                    <img src={loadingImg} alt="Loading..." className="mx-auto mb-4 h-10" loading="lazy" />
                  </div>
                )}
              </div>
            </div>
            
            {/* Mission Section */}
            <div className="p-6 border-2 border-green-500 rounded-xl shadow-lg bg-white w-full lg:w-1/2 hover-shadow-mission transition-all duration-300 flex flex-col">
              <h3 className="text-xl lg:text-2xl  mb-4 text-center text-black-700 2xl:text-3xl pb-2 border-b border-green-200">
                
                  Mission
                
              </h3>
              <div className="flex-grow flex items-center">
                {aboutUs?.mission ? (
                  <p className="text-sm md:text-base lg:text-base 3xl:text-lg text-gray-700 leading-relaxed">
                    {aboutUs.mission}
                  </p>
                ) : (
                  <div className="text-center w-full">
                    <img src={loadingImg} alt="Loading..." className="mx-auto mb-4 h-10" loading="lazy" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Events Section */}
        <div className="w-full lg:w-[38%] h-auto flex items-center justify-center lg:p-4 pr-0 flex-col px-4">
          <div className="w-full h-auto min-h-[20rem] bg-white shadow-xl rounded-md pt-4 px-4 xl:px-5 shadow-blink m-auto">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#192f59] text-center mb-4 sm:mb-6 md:mb-8 relative ">
            <span className="relative inline-block">
              Events
              <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-1/2 h-1 bg-blue-500 rounded-full "></span>
            </span>
          </h2>
            {loading ? (
              <div className="text-center">
                <img src={loadingImg} alt="Loading..." className="mx-auto mb-4 h-20" loading="lazy" />
              </div>
            ) : (
              <Swiper
                direction={"horizontal"}
                pagination={{ clickable: true }}
                autoplay={{ delay: 2500, disableOnInteraction: false }}
                loop={true}
                speed={1000}
                modules={[Pagination, Autoplay]}
                className="mySwiper style-1 min-h-[28rem] md:min-h-auto lg:min-h-auto"
              >
                {eventSlides}
              </Swiper>
            )}
            <div className="text-right my-4">
              <Link to="/events" className="text-blue-500 hover:text-blue-300 active:text-blue-400 px-2">
                More Events
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsAndEvents;
