import React from 'react'
import { DfiieObjective } from '../../Data/About'
import dbatuforum from '../images/objective.png'
import { Helmet } from 'react-helmet-async'
import "../style.css"
import GalleryHome from '../Home/galleryHome'
import ValuePartners from '../Home/Partners'
import ImageSlider from '../ImageSlider'
import { motion } from 'framer-motion'
import { FaCheckCircle } from 'react-icons/fa'

const DFIIEObjective = () => {
  return (
    <>
      <Helmet>
        <title>Strategic Objectives | DFIIE</title>
        <meta name="description" content="Explore the strategic objectives and institutional mission of DFIIE in advancing innovation, entrepreneurship, and sustainable development." />
      </Helmet>
      <ImageSlider />
      
      <div className='w-full bg-gradient-to-b from-white via-blue-50/30 to-white py-16 md:py-24'>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className='container mx-auto px-4 sm:px-6 lg:px-8'
        >
          <div className='text-center mb-12'>
            <motion.h1 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className='text-3xl sm:text-4xl md:text-5xl font-bold text-[#192f59] relative tracking-tight mb-6'
            >
              <span className="relative inline-block">
                Strategic Objectives
                <span className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 w-24 sm:w-32 h-1.5 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full"></span>
              </span>
            </motion.h1>
            <motion.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="text-lg text-gray-600 max-w-3xl mx-auto"
            >
              Fostering innovation ecosystems through strategic initiatives, industry partnerships, and research excellence to drive sustainable economic development
            </motion.p>
          </div>
          
          <div className='bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100'>
            <div className='flex flex-col md:flex-row items-center'>
              <motion.div 
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.3 }}
                className='md:w-1/2 p-8 md:p-12 lg:p-16'
              >
                <h2 className='text-2xl md:text-3xl font-bold text-[#192f59] mb-8'>Institutional Mandate</h2>
                <div className='space-y-6'>
                  {DfiieObjective.map((data, index) => (
                    <motion.div 
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.4 + (index * 0.1) }}
                      className='flex items-start gap-4'
                    >
                      <FaCheckCircle className='text-blue-600 text-xl flex-shrink-0 mt-1' />
                      <p className='text-gray-700 leading-relaxed text-base md:text-lg'>{data}</p>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
              
              <motion.div 
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.5 }}
                className='md:w-1/2 relative overflow-hidden'
              >
                <div className='bg-gradient-to-br from-blue-500/10 to-indigo-500/10 p-8 md:p-0 h-full'>
                  <motion.img 
                    whileHover={{ scale: 1.03 }}
                    transition={{ duration: 0.4 }}
                    src={dbatuforum} 
                    className='w-full h-auto object-contain rounded-lg shadow-lg' 
                    alt="DFIIE Strategic Framework" 
                  />
                </div>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
      
      <ValuePartners />
      <GalleryHome />
    </>
  )
}

export default DFIIEObjective
