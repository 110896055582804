import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { FaLinkedin, FaQuoteLeft, FaArrowRight } from 'react-icons/fa';

// Importing team member images
import Kiwalekar from "../images/ceo.jpg";
import sameerPunaskar from "../images/sameerPunaskar.jpg";
import sheetalSharma from "../images/sheetalSharma.jpg";

const Team = () => {
  const [loadedImages, setLoadedImages] = useState({});
  
  // Array of team members with their details
  const teamMembers = [
    {
      "id": 1,
      "name": "Dr. Navin Khandare",
      "image": Kiwalekar,
      "designation": "Chief Executive Officer",
      "bio": "Visionary leader with extensive experience in innovation and technology transformation.",
      "quote": "Innovation is not just about ideas; it's about solving problems and creating value that transforms industries.",
      "linkedin": "https://www.linkedin.com/in/drnavinkhandare/"
    },
    {
      "id": 2,
      "name": "Sameer Punaskar",
      "image": sameerPunaskar,
      "designation": "Incubation Manager",
      "bio": "Strategic innovation expert specializing in startup growth and ecosystem development.",
      "quote": "Building successful startups requires both vision and execution. We provide the framework for both.",
      "linkedin": "https://www.linkedin.com/in/sameer-punaskar-415757b/"
    },
    {
      "id": 3,
      "name": "Sheetal Sharma",
      "image": sheetalSharma,
      "designation": "Accounts Officer",
      "bio": "Financial management professional with expertise in corporate accounting and compliance.",
      "quote": "Sound financial management is the backbone of sustainable innovation and growth.",
      "linkedin": "https://www.linkedin.com/in/sheetal-sharma-840a34296/"
    },
  ];

  const handleImageLoad = (id) => {
    setLoadedImages(prev => ({
      ...prev,
      [id]: true
    }));
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Leadership | DFIIE</title>
        <meta name="description" content="Meet the visionary leaders driving innovation and excellence at DFIIE." />
      </Helmet>
      
      
      {/* CEO Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-16"
          >
           <h1 className='text-3xl md:text-4xl font-bold text-center text-[#192f59] mb-10'>
            <span className='relative inline-block'>
              Executive Team
              <span className='absolute -bottom-3 left-1/2 transform -translate-x-1/2 w-1/2 h-1 bg-blue-500 rounded-full'></span>
            </span>
          </h1>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="mb-24"
          >
            <div className="bg-white rounded-2xl shadow-2xl overflow-hidden max-w-6xl mx-auto transform transition-all duration-500 hover:shadow-3xl">
              <div className="flex flex-col lg:flex-row">
                <div className="lg:w-2/5 relative overflow-hidden">
                  <div className={`absolute inset-0 bg-gray-200 animate-pulse ${loadedImages[1] ? 'hidden' : 'block'}`}></div>
                  <img 
                    src={teamMembers[0].image} 
                    alt={teamMembers[0].name}
                    onLoad={() => handleImageLoad(1)}
                    className={`w-full h-full object-cover object-center transition-opacity duration-300 ${loadedImages[1] ? 'opacity-100' : 'opacity-0'}`}
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-500"></div>
                </div>
                <div className="lg:w-3/5 p-8 lg:p-12 flex flex-col justify-center">
                  <div className="inline-block px-4 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-semibold mb-4">
                    Executive Leadership
                  </div>
                  <h3 className="text-3xl lg:text-4xl font-bold text-[#192f59] mb-3">{teamMembers[0].name}</h3>
                  <p className="text-xl text-blue-600 font-medium mb-6">{teamMembers[0].designation}</p>
                  
                  <div className="mb-8 relative pl-8 border-l-4 border-blue-500 italic">
                    <FaQuoteLeft className="text-blue-400 text-2xl absolute top-0 left-3 -translate-x-1/2" />
                    <p className="text-gray-700 text-lg">
                      "{teamMembers[0].quote}"
                    </p>
                  </div>
                  
                  <p className="text-gray-700 mb-8 leading-relaxed text-lg">
                    Dr. Khandare brings exceptional vision and strategic leadership to DFIIE. With a distinguished background in innovation and technology transformation, he leads our organization toward pioneering breakthroughs that address global challenges and create sustainable impact across industries.
                  </p>
                  
                  <a 
                    href={teamMembers[0].linkedin} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-300 group shadow-md hover:shadow-lg transform hover:-translate-y-1"
                  >
                    <FaLinkedin className="text-xl mr-2" />
                    <span className="font-medium">Connect on LinkedIn</span>
                    <FaArrowRight className="ml-2 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all duration-300" />
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
          
          {/* Leadership Team */}
          <div className="mb-16">
            <motion.h3
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-2xl sm:text-3xl font-bold text-center text-[#192f59] mb-16"
            >
              Leadership Team
            </motion.h3>
            
            <motion.div
              initial="hidden"
              animate="visible"
              variants={containerVariants}
              className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-16"
            >
              {teamMembers.slice(1).map((member) => (
                <motion.div
                  key={member.id}
                  variants={itemVariants}
                  whileHover={{ 
                    y: -10, 
                    transition: { duration: 0.3 } 
                  }}
                  className="bg-white rounded-xl overflow-hidden shadow-xl border border-gray-100 hover:border-blue-200 transition-all duration-300 flex flex-col h-full group"
                >
                  <div className="bg-gradient-to-r from-blue-600 to-indigo-700 h-28 relative">
                    <div className="absolute -bottom-16 left-8">
                      <div className="w-32 h-32 rounded-full overflow-hidden ring-4 ring-white shadow-lg relative">
                        <div className={`absolute inset-0 bg-gray-200 animate-pulse ${loadedImages[member.id] ? 'hidden' : 'block'}`}></div>
                        <img 
                          src={member.image} 
                          alt={member.name}
                          onLoad={() => handleImageLoad(member.id)}
                          className={`w-full h-full object-cover object-center transition-opacity duration-300 ${loadedImages[member.id] ? 'opacity-100' : 'opacity-0'}`}
                        />
                      </div>
                    </div>
                    <div className="absolute top-4 right-4">
                      <a 
                        href={member.linkedin} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="flex items-center justify-center w-10 h-10 bg-white/20 hover:bg-white/40 rounded-full transition-all duration-300"
                      >
                        <FaLinkedin className="text-white text-xl" />
                      </a>
                    </div>
                  </div>
                  
                  <div className="pt-20 px-8 pb-8 flex flex-col flex-grow">
                    <h3 className="text-2xl font-bold text-[#192f59] mb-1">{member.name}</h3>
                    <p className="text-blue-600 font-medium mb-6">{member.designation}</p>
                    
                    <div className="bg-blue-50 rounded-xl p-6 mb-6 relative transform transition-transform duration-300 group-hover:scale-[1.02]">
                      <FaQuoteLeft className="text-blue-200 text-3xl absolute top-3 left-3" />
                      <p className="text-gray-700 italic pt-4 px-2">
                        "{member.quote}"
                      </p>
                    </div>
                    
                    <p className="text-gray-700 mb-6">{member.bio}</p>
                    
                    <div className="mt-auto pt-4">
                      <div className="h-1 w-16 bg-blue-500 rounded-full mb-4 transition-all duration-300 group-hover:w-24"></div>
                      <a 
                        href={member.linkedin} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="inline-flex items-center text-blue-600 hover:text-blue-800 transition-colors duration-300 font-medium"
                      >
                        Learn more
                        <FaArrowRight className="ml-2 text-sm transition-transform duration-300 group-hover:translate-x-1" />
                      </a>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </div>
          
          {/* <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8, duration: 1 }}
            className="mt-20 text-center max-w-4xl mx-auto bg-gradient-to-r from-blue-50 to-indigo-50 rounded-2xl p-10 shadow-sm"
          >
            <h4 className="text-2xl font-bold text-[#192f59] mb-4">Join Our Team</h4>
            <p className="text-lg text-gray-700 mb-6">
              We're always looking for talented individuals who share our passion for innovation and excellence.
            </p>
            <a 
              href="/careers" 
              className="inline-flex items-center px-6 py-3 bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-lg hover:from-blue-700 hover:to-indigo-700 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1"
            >
              <span className="font-medium">Explore Opportunities</span>
              <FaArrowRight className="ml-2" />
            </a>
          </motion.div> */}
        </div>
      </section>
    </>
  );
}

export default Team;
