import React, { useEffect, useState, useMemo } from "react";
import MainLayout from '../layout/Main.layout';
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import loadingImg from "../components/images/Spin.gif"; // Loading spinner image

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cacheExpiration = 10 * 60 * 1000; // 10 minutes
    const fetchEvents = async () => {
      try {
        const now = new Date().getTime();
        let eventsData;
        const cachedEvents = localStorage.getItem("events");
        
        if (cachedEvents) {
          const { data: cachedData, timestamp } = JSON.parse(cachedEvents);
          if (now - timestamp < cacheExpiration) {
            eventsData = cachedData;
          }
        }
        
        if (!eventsData) {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/events/`);
          eventsData = response.data.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          localStorage.setItem("events", JSON.stringify({ data: eventsData, timestamp: now }));
        }
        
        setEvents(eventsData);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching events:", err);
        setError("Failed to load events. Please try again later.");
        setLoading(false);
      }
    };
    fetchEvents();
  }, []);

  // Memoize the events grid to prevent unnecessary re-renders
  const eventsGrid = useMemo(() => {
    if (loading || error || events.length === 0) {
      return null;
    }

    return (
      <div className="grid md:grid-cols-4 gap-4">
        {events.map((event, index) => (
          <div key={index} className="border shadow rounded-2xl overflow-hidden bg-white">
            <PhotoProvider>
              <PhotoView src={event.image}>
                <div className="w-full max-h-96">
                  <img
                    src={event.image}
                    alt={event.title}
                    loading="lazy"
                    className="w-auto h-auto object-cover cursor-pointer transition-transform transform hover:scale-105 duration-500 ease-in-out"
                  />
                </div>
              </PhotoView>
            </PhotoProvider>
            <div className="p-2">
              <h1 className="font-semibold text-1xl py-2.5">{event.title}</h1>
              <p className="pt-1.5 text-sm" style={{ textAlign: "justify" }}>
                {event.description}
              </p>
              <p className="text-blue-600 font-semibold">
                {new Date(event.date).toLocaleDateString('en-US', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  }, [events, loading, error]);

  return (
    <>
      <Helmet>
        <title>Events | DFIIE</title>
      </Helmet>

      <div className="w-full h-auto flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center py-2.5 px-3 rounded-xl h-auto lg:w-10/12 w-11/12">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#192f59] text-center mb-4 sm:mb-6 md:mb-8 relative pb-8">
            <span className="relative inline-block">
              Events
              <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-1/2 h-1 bg-blue-500 rounded-full "></span>
            </span>
          </h2>

          {/* Loading State */}
          {loading && (
            <div className="text-center">
              <img src={loadingImg} alt="Loading..." className="mx-auto mb-4 h-20" />
              <p>Loading events...</p>
            </div>
          )}

          {/* Error State */}
          {error && (
            <div className="text-center text-red-600">
              <p>{error}</p>
            </div>
          )}

          {/* No Events Fallback */}
          {!loading && events.length === 0 && !error && (
            <div className="text-center text-gray-600">
              <p>No events available at the moment. Please check back later.</p>
            </div>
          )}

          {/* Events Grid - Using memoized component */}
          {eventsGrid}
        </div>
      </div>
    </>
  );
};

export default MainLayout(Events);
