import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import kale from "../images/vcpic.jpg";
import malkani from "../images/malkani.png";
import jogi from '../images/ragistarpic.jpg';
import singh from '../images/singh.png';
import nalbalwar from '../images/dircterpic.jpg';
import john from '../images/vivekmogal.jpg';
import kamble from '../images/kamble.png';

const BoardOfDirector = () => {
  const chairman = {
    "name": "Colonel Prof. (Dr.) K. V. Kale",
    "image": kale,
    "designation": "Hon'ble Vice Chancellor – Dr. BATU & Chairman- DFIIE",
    "bio": "Distinguished academic leader with extensive experience in educational administration and strategic governance."
  };
  
  const boardMembers = [
    {
      "name": "Dr. Milind Kamble",
      "image": kamble,
      "designation": "Director",
      "bio": "Accomplished professional with expertise in strategic planning and organizational development."
    },
    {
      "name": "Shri. Tarun Malkani",
      "image": malkani,
      "designation": "Director",
      "bio": "Industry veteran with significant contributions to innovation and business transformation."
    },
    {
      "name": "Rtn. Manpreet Singh",
      "image": singh,
      "designation": "Director",
      "bio": "Dedicated leader with a strong commitment to community service and organizational excellence."
    },
    {
      "name": "Dr. S. L. Nalbalwar",
      "image": nalbalwar,
      "designation": "Director",
      "bio": "Respected academic with deep knowledge in research and educational leadership."
    },
    {
      "name": "Dr. A. W. Kiwelekar",
      "image": jogi,
      "designation": "Invitee Member",
      "bio": "Valued advisor bringing critical insights to the organization's strategic initiatives."
    },
    {
      "name": "Shri. Vivek Mogal",
      "image": john,
      "designation": "Observer Member- MSInS",
      "bio": "Strategic observer ensuring alignment with broader institutional objectives and policies."
    } 
  ];
  
  const [loadedImages, setLoadedImages] = useState({});
  
  const handleImageLoad = (id) => {
    setLoadedImages(prev => ({
      ...prev,
      [id]: true
    }));
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };
  
  return (
    <>
      <Helmet>
        <title>Board of Directors | DFIIE</title>
        <meta name="description" content="Meet the distinguished Board of Directors leading DFIIE's vision and strategic direction." />
      </Helmet>
      
      <div className="min-h-screen bg-gradient-to-b from-white via-blue-50/30 to-white py-16 md:py-24">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-16"
          >
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-[#192f59] relative tracking-tight mb-6">
              <span className="relative inline-block">
                Board of Directors
                <span className="absolute -bottom-3 left-1/2 transform -translate-x-1/2 w-24 sm:w-32 h-1.5 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full"></span>
              </span>
            </h1>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              Distinguished leaders guiding DFIIE's strategic vision with expertise and commitment to innovation excellence
            </p>
          </motion.div>
          
          {/* Chairman Section */}
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="mb-24"
          >
            <div className="bg-white rounded-2xl shadow-xl overflow-hidden max-w-4xl mx-auto transform transition-all duration-500 hover:shadow-2xl border border-gray-100">
              <div className="flex flex-col md:flex-row items-center">
                <div className="md:w-2/5 relative overflow-hidden md:h-auto p-6">
                  <div className={`absolute inset-0 bg-gray-200 animate-pulse ${loadedImages["chairman"] ? 'hidden' : 'block'}`}></div>
                  <img 
                    src={chairman.image} 
                    alt={chairman.name}
                    onLoad={() => handleImageLoad("chairman")}
                    className={`w-full h-auto object-contain transition-opacity duration-300 rounded-xl shadow-md ${loadedImages["chairman"] ? 'opacity-100' : 'opacity-0'}`}
                  />
                </div>
                <div className="md:w-3/5 p-8 md:p-12 flex flex-col justify-center">
                  <div className="inline-block px-4 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-semibold mb-4">
                    Chairman of the Board
                  </div>
                  <h3 className="text-2xl md:text-3xl font-bold text-[#192f59] mb-3">{chairman.name}</h3>
                  <p className="text-lg text-blue-600 font-medium mb-6">{chairman.designation}</p>
                  <p className="text-gray-700 leading-relaxed text-base md:text-lg">{chairman.bio}</p>
                </div>
              </div>
            </div>
          </motion.div>
          
          {/* Board Members Section */}
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-10"
          >
            {boardMembers.map((member, index) => (
              <motion.div
                key={member.name}
                variants={itemVariants}
                whileHover={{ 
                  y: -8, 
                  boxShadow: '0 20px 40px -12px rgba(0, 0, 0, 0.15)', 
                  transition: { duration: 0.3 } 
                }}
                className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl border border-gray-100 transition-all duration-300 flex flex-col h-full"
              >
                <div className="relative h-auto p-5 bg-gradient-to-r from-blue-50 to-indigo-50 flex items-center justify-center">
                  <div className={`absolute inset-0 bg-gray-200 animate-pulse ${loadedImages[index] ? 'hidden' : 'block'}`}></div>
                  <img 
                    src={member.image} 
                    alt={member.name} 
                    onLoad={() => handleImageLoad(index)}
                    className={`w-auto max-h-64 object-contain transition-all duration-500 rounded-lg shadow-md ${loadedImages[index] ? 'opacity-100 scale-100' : 'opacity-0 scale-105'}`}
                  />
                </div>
                <div className="p-6 flex flex-col flex-grow">
                  <div className="inline-block px-3 py-1 bg-blue-50 text-blue-700 rounded-full text-xs font-medium mb-3">
                    {member.designation}
                  </div>
                  <h3 className="text-xl font-bold text-gray-800 mb-2">{member.name}</h3>
                  <p className="text-gray-600 text-sm flex-grow leading-relaxed">{member.bio}</p>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default BoardOfDirector;
