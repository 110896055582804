import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import loadingImg from "../images/Spin.gif";

export default function Gallery() {
  const [galleryImages, setGalleryImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const swiperRef = useRef(null);

  useEffect(() => {
    const cacheExpiration = 10 * 60 * 1000; // 10 minutes
    
    const fetchGalleryImages = async () => {
      try {
        const now = new Date().getTime();
        let imagesData;
        const cachedImages = localStorage.getItem("galleryImages");
        
        if (cachedImages) {
          const { data: cachedData, timestamp } = JSON.parse(cachedImages);
          if (now - timestamp < cacheExpiration) {
            imagesData = cachedData;
          }
        }
        
        if (!imagesData) {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/gallery/`);
          imagesData = response.data;
          localStorage.setItem("galleryImages", JSON.stringify({ data: imagesData, timestamp: now }));
        }
        
        setGalleryImages(imagesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching gallery images:", error);
        setLoading(false);
      }
    };

    fetchGalleryImages();
  }, []);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.autoplay.start();
    }
  }, [galleryImages]);

  // Limit the images to only 10 and memoize to prevent unnecessary re-renders
  const displayedImages = useMemo(() => {
    return galleryImages.slice(0, 10);
  }, [galleryImages]);

  // Memoize the gallery slides to prevent unnecessary re-renders
  const gallerySlides = useMemo(() => {
    return displayedImages.map((image) => (
      <SwiperSlide key={image.id}>
        <div className="gallery-card overflow-hidden rounded-xl shadow-lg transition-all duration-300 hover:shadow-2xl transform hover:-translate-y-2 bg-white">
          <Link to={`/gallery/`} className="block overflow-hidden">
            <div className="relative overflow-hidden group">
              <img
                src={`${image.image}`}
                alt={`Gallery item ${image.id}`}
                className="w-full h-48 object-cover object-center transition-transform duration-700 ease-in-out group-hover:scale-110"
                loading="lazy"
                style={{ aspectRatio: '16/9' }}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end">
                <span className="text-white font-medium p-4 text-sm">View Gallery</span>
              </div>
            </div>
          </Link>
        </div>
      </SwiperSlide>
    ));
  }, [displayedImages]);

  return (
    <div className="bg-gradient-to-r from-gray-50 to-white py-12">
      <div className="container mx-auto">
        <h2 className="text-[#192f59] font-bold text-3xl md:text-4xl text-center mb-8 relative">
          <span className="relative inline-block">
            Gallery
            <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-12 h-1 bg-blue-500 rounded-full"></span>
          </span>
        </h2>
        
        <div className="px-4 sm:px-6 md:px-8 lg:px-12">
          <Swiper
            ref={swiperRef}
            slidesPerView={1}
            spaceBetween={20}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            speed={1000}
            navigation={{ 
              nextEl: '.swiper-button-next-custom',
              prevEl: '.swiper-button-prev-custom'
            }}
            modules={[Navigation, Autoplay]}
            className="gallery-swiper"
            breakpoints={{
              640: { slidesPerView: 1, spaceBetween: 20 },
              768: { slidesPerView: 2, spaceBetween: 20 },
              1024: { slidesPerView: 3, spaceBetween: 20 },
              1436: { slidesPerView: 4, spaceBetween: 20 },
              1800: { slidesPerView: 5, spaceBetween: 20 },
            }}
          >
            {loading || displayedImages.length === 0 ? (
              <div className="text-center py-16 w-full">
                <img src={loadingImg} alt="Loading" className="mx-auto mb-4 h-20" />
                <p className="text-gray-500">Loading gallery images...</p>
              </div>
            ) : (
              gallerySlides
            )}
          </Swiper>
          
          {displayedImages.length > 0 && (
            <div className="flex justify-center mt-8">
              <Link 
                to="/gallery" 
                className="inline-flex items-center px-6 py-3 bg-[#192f59] text-white font-medium rounded-lg transition-all duration-300 hover:bg-blue-700 hover:shadow-lg"
              >
                View Full Gallery
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
