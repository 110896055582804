import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import "swiper/css";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import axios from "axios";
import loadingImg from "../images/Spin.gif";


export default function Partner() {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPartners = async () => {
      // Cache expiration time (10 minutes)
      const cacheExpiration = 10 * 60 * 1000;
      const now = new Date().getTime();
      const cachedData = localStorage.getItem("partners");

      // Check if we have cached data that hasn't expired
      if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData);
        if (now - timestamp < cacheExpiration) {
          setPartners(data);
          setLoading(false);
          return;
        } else {
          // Clear expired cache
          localStorage.removeItem("partners");
        }
      }

      // Fetch fresh data if no cache or cache expired
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/value-partners/`);
        setPartners(response.data);
        
        // Store in cache with timestamp
        localStorage.setItem(
          "partners",
          JSON.stringify({ data: response.data, timestamp: now })
        );
      } catch (error) {
        console.error('Error fetching partners:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPartners();
  }, []);

  // Memoize the partner slides to prevent unnecessary re-renders
  const partnerSlides = useMemo(() => {
    return partners.map((partner, index) => (
      <SwiperSlide key={`partner-${partner.id || index}`}>
        <Link to={`/stakeHolders/partner`} className="block">
          <div className="group flex justify-center items-center bg-white rounded-lg sm:rounded-xl shadow-md sm:shadow-lg md:shadow-xl p-4 sm:p-6 md:p-8 h-36 sm:h-44 md:h-56 mx-1 sm:mx-2 border border-gray-100 backdrop-blur-sm transition-all duration-500 hover:shadow-2xl hover:border-blue-100 hover:bg-gradient-to-b hover:from-white hover:to-blue-50 transform hover:-translate-y-1 sm:hover:-translate-y-2">
            <img
              src={`${partner.image}`}
              alt={partner.name || "Partner logo"}
              className="max-h-24 sm:max-h-32 md:max-h-40 w-auto object-contain filter group-hover:brightness-110 transition-all duration-300"
              loading="lazy"
            />
          </div>
        </Link>
      </SwiperSlide>
    ));
  }, [partners]);

  // Memoize the duplicated partner slides for the continuous scrolling effect
  const duplicatedPartnerSlides = useMemo(() => {
    // Create four copies with unique section identifiers in the keys
    return [
      ...partnerSlides.map((slide, index) => 
        React.cloneElement(slide, { key: `section1-${slide.key || `partner-section1-${index}`}` })
      ),
      ...partnerSlides.map((slide, index) => 
        React.cloneElement(slide, { key: `section2-${slide.key || `partner-section2-${index}`}` })
      ),
      ...partnerSlides.map((slide, index) => 
        React.cloneElement(slide, { key: `section3-${slide.key || `partner-section3-${index}`}` })
      ),
      ...partnerSlides.map((slide, index) => 
        React.cloneElement(slide, { key: `section4-${slide.key || `partner-section4-${index}`}` })
      )
    ];
  }, [partnerSlides]);

  return (
    <>
      {loading ? (
        <div className="text-center py-8 sm:py-12 md:py-16 bg-white">
          <img src={loadingImg} alt="Loading partners" className="mx-auto mb-4 h-16 sm:h-20 md:h-24 animate-pulse" />
          <p className="text-gray-500 text-sm sm:text-base font-light italic">Loading prestigious partnerships...</p>
        </div>
      ) : (
        <Fade direction="up" triggerOnce={true} cascade damping={0.1}>
          <section className="py-10 sm:py-14 md:py-20 bg-gradient-to-b from-white via-gray-50 to-white overflow-hidden">
            <div className="mx-auto px-4 ">
              <div className="text-center mb-8 sm:mb-12 md:mb-16">
                <h2 className="text-[#192f59] font-bold text-3xl sm:text-4xl md:text-5xl mb-4 sm:mb-6 tracking-tight">Strategic Alliances</h2>
                <div className="w-16 sm:w-20 md:w-24 h-1 bg-gradient-to-r from-blue-500 to-indigo-600 mx-auto mb-4 sm:mb-6"></div>
                <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-3xl mx-auto font-light leading-relaxed px-2">
                  Partnering with global industry leaders to shape the future of innovation and excellence
                </p>
              </div>
              
              <div className="relative px-2 sm:px-6 md:px-12 py-4 sm:py-6 md:py-8 -mx-2 sm:-mx-4">
                <div className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-50/30 to-transparent"></div>
                
                {/* First row - continuously moving left to right */}
                <div className="mb-6 sm:mb-8 md:mb-12 overflow-hidden">
                  <div className="relative w-full">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={10}
                      loop={true}
                      speed={8000}
                      autoplay={{
                        delay: 0,
                        disableOnInteraction: false,
                        reverseDirection: false,
                        pauseOnMouseEnter: false,
                      }}
                      allowTouchMove={false}
                      modules={[Autoplay]}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                        480: {
                          slidesPerView: 2,
                          spaceBetween: 15,
                        },
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 40,
                        },
                        1280: {
                          slidesPerView: 5,
                          spaceBetween: 50,
                        },
                      }}
                      className="partners-swiper-row1 py-4 sm:py-6 md:py-8"
                    >
                      {duplicatedPartnerSlides}
                    </Swiper>
                  </div>
                </div>
                
                {/* Second row - continuously moving right to left */}
                <div className="overflow-hidden">
                  <div className="relative w-full">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={10}
                      loop={true}
                      speed={8000}
                      autoplay={{
                        delay: 0,
                        disableOnInteraction: false,
                        reverseDirection: true,
                        pauseOnMouseEnter: false,
                      }}
                      allowTouchMove={false}
                      modules={[Autoplay]}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                        480: {
                          slidesPerView: 2,
                          spaceBetween: 15,
                        },
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 40,
                        },
                        1280: {
                          slidesPerView: 5,
                          spaceBetween: 50,
                        },
                      }}
                      className="partners-swiper-row2 py-4 sm:py-6 md:py-8"
                    >
                      {duplicatedPartnerSlides}
                    </Swiper>
                  </div>
                </div>
              </div>
              
              <div className="text-center mt-8 sm:mt-12 md:mt-16">
                <Link 
                  to="/stakeHolders/partner" 
                  className="inline-flex items-center px-5 sm:px-6 md:px-8 py-3 sm:py-3.5 md:py-4 bg-gradient-to-r from-[#192f59] to-[#2a4580] text-white text-sm sm:text-base md:text-lg font-medium rounded-full shadow-md sm:shadow-lg hover:shadow-xl transform transition-all duration-300 hover:-translate-y-1 focus:ring-4 focus:ring-blue-200 focus:outline-none"
                >
                  Explore Our Network
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </Link>
              </div>
            </div>
          </section>
        </Fade>
      )}
    </>
  );
}
