import React from 'react';
import { Helmet } from 'react-helmet-async';
import { focus1, focus2, focus3, focus4, focus5, focus6 } from './ImageImports';
import Image from '../Image';
import { motion } from 'framer-motion';

const FocusArea = () => {
  const focusAreas = [
    { image: focus1, title: "Food & Agriculture Tech", description: "Revolutionizing food systems through sustainable agricultural technologies" },
    { image: focus2, title: "Healthcare & Biotech", description: "Advancing medical solutions and biotechnology innovations for better health outcomes" },
    { image: focus3, title: "Digital Innovation", description: "Creating cutting-edge digital solutions to transform industries and experiences" },
    { image: focus4, title: "Social Innovation", description: "Developing impactful solutions to address pressing social challenges" },
    { image: focus5, title: "E-Mobility", description: "Pioneering electric transportation solutions for sustainable urban mobility" },
    { image: focus6, title: "Renewable/Green Energy", description: "Harnessing clean energy technologies for a sustainable and carbon-neutral future" }
  ];
  
  return (
    <>
      <Helmet>
        <title>Focus Areas | DFIIE</title>
        <meta name="description" content="Explore the key focus areas of DFIIE including research, sustainability, and digital transformation." />
      </Helmet>

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16 md:py-20 bg-gradient-to-b from-white via-blue-50/30 to-white">
        <motion.h1 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-center mb-6 sm:mb-8 md:mb-10 text-[#192f59] relative tracking-tight'
        >
          <span className="relative inline-block">
            Our Focus Areas
            <span className="absolute -bottom-3 sm:-bottom-4 left-1/2 transform -translate-x-1/2 w-24 sm:w-28 md:w-32 h-1 sm:h-1.5 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full"></span>
          </span>
        </motion.h1>
        
        <motion.p 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="text-base sm:text-lg md:text-xl lg:text-2xl text-gray-700 text-center max-w-4xl mx-auto mb-10 sm:mb-14 md:mb-20 leading-relaxed font-light px-2 sm:px-4"
        >
          At DFIIE, we harness cutting-edge expertise to pioneer transformative solutions across these strategic domains, driving innovation that reshapes industries and elevates global communities.
        </motion.p>
        
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 md:gap-10 py-4 px-2 sm:px-4 md:px-8 lg:px-12'>
          {focusAreas.map((area, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: index * 0.15 }}
              whileHover={{ 
                y: -10, 
                boxShadow: '0 20px 40px -12px rgba(0, 0, 0, 0.2)', 
                transition: { duration: 0.3 } 
              }}
              className="flex flex-col bg-white rounded-xl sm:rounded-2xl overflow-hidden shadow-lg hover:shadow-2xl border border-gray-100 hover:border-blue-200 transition-all duration-300 group h-full"
            >
              <div className="relative overflow-hidden h-48 sm:h-56 md:h-64 lg:h-72">
                <Image 
                  src={area.image} 
                  alt={area.title} 
                  classData='w-full h-full object-cover transition-transform duration-700 group-hover:scale-110 filter group-hover:brightness-110' 
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                <div className="absolute bottom-0 left-0 w-full p-4 sm:p-6 transform translate-y-full group-hover:translate-y-0 transition-transform duration-500">
                  <span className="inline-block px-3 py-1 sm:px-4 sm:py-1 bg-blue-600 text-white text-xs sm:text-sm font-medium rounded-full shadow-lg">Explore</span>
                </div>
              </div>
              <div className="p-5 sm:p-6 md:p-8 flex-grow bg-gradient-to-br from-white to-blue-50/30">
                <h3 className="text-xl sm:text-2xl font-bold text-[#192f59] mb-3 sm:mb-4 border-b border-blue-100 pb-2 sm:pb-3 group-hover:text-blue-700 transition-colors duration-300">{area.title}</h3>
                <p className="text-gray-700 leading-relaxed text-sm sm:text-base md:text-lg">{area.description}</p>
              </div>
              <div className="px-5 sm:px-6 md:px-8 pb-4 sm:pb-5 md:pb-6">
                <div className="w-12 sm:w-16 h-1 sm:h-1.5 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full opacity-75 group-hover:w-20 sm:group-hover:w-24 transition-all duration-300 ease-in-out"></div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
}

export default FocusArea;
