import React, { useEffect, useState } from 'react'; // Import React and hooks
import axios from 'axios'; // Import axios for HTTP requests
import { Helmet } from 'react-helmet-async'; // Import Helmet for managing document head
import '../style.css'; // Import custom styles
import Image from '../Image'; // Import custom Image component
import { valuedPartner } from '../../Data/StakeHolders'; // Import data for valued partners
import partnerImage from "../images/partner.png"; // Import partner image

const ValuedPartner = () => {
  const [images, setImages] = useState([]); // State to hold partner images

  // useEffect hook to fetch partner images on component mount
  useEffect(() => {
    const fetchImages = async () => {
      try {
        // Fetch images from the API
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/value-partners/`);
        setImages(response.data); // Set fetched images to state
      } catch (error) {
        console.error('Error fetching images:', error); // Log any errors
      }
    };

    fetchImages(); // Call the fetch function
  }, []); // Empty dependency array means this runs once on mount

  return (
    <>
      <Helmet>
        <title>Valued Partners | DFIIE</title>
      </Helmet>
      
      {/* Hero Section */}
      <div className='w-full bg-gradient-to-r from-blue-50 to-indigo-50 py-12'>
        <div className='container mx-auto px-4'>
          <h1 className='text-3xl md:text-4xl font-bold text-center text-[#192f59] mb-6'>
            <span className='relative inline-block'>
              Our Valued Partners
              <span className='absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-1/2 h-1 bg-blue-500 rounded-full'></span>
            </span>
          </h1>
          
          <div className='flex md:flex-row flex-col-reverse items-center gap-8 max-w-5xl mx-auto'>
            <div className='md:w-2/3 w-full'>
              <div className='bg-white p-6 rounded-lg shadow-md'>
                {valuedPartner.map((data, index) => (
                  <p key={index} className='text-gray-700 leading-relaxed mb-4 text-justify'>{data}</p>
                ))}
              </div>
            </div>
            <div className='md:w-1/3 w-full flex justify-center'>
              <img 
                src={partnerImage} 
                className='w-3/4 md:w-full object-contain rounded-lg shadow-lg hover:shadow-xl transition-all duration-300' 
                alt="Partnership illustration" 
              />
            </div>
          </div>
        </div>
      </div>
      
      {/* Partners Logo Grid */}
      <div className='container mx-auto py-12 px-4'>
        <h2 className='text-2xl md:text-3xl font-semibold text-center text-[#192f59] mb-10'>
          <span className='relative inline-block'>
            Collaborating With Excellence
            <span className='absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-1/2 h-1 bg-blue-500 rounded-full'></span>
          </span>
        </h2>
        
        <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8 justify-items-center'>
          {images.length > 0 ? (
            images.map((image) => (
              <div key={image.id} className='hover:scale-105 transition-transform duration-300'>
                <Image 
                  src={`${image.image}`} 
                  alt="Partner Logo" 
                  classData='w-full max-w-[150px] h-auto shadow-md rounded-md hover:shadow-lg transition-all duration-300' 
                />
              </div>
            ))
          ) : (
            <div className='col-span-full text-center py-10'>
              <div className='animate-pulse flex flex-col items-center'>
                <div className='h-12 w-12 mb-4 rounded-full bg-gray-200'></div>
                <p className='text-gray-500'>Loading partner information...</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ValuedPartner;
