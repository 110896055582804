import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'react-photo-view/dist/react-photo-view.css';
import './App.css';
import HomePage from './pages/HomePage';
import Contact from './pages/Contact';
import NewsAndAnnouncement from './pages/NewsAndAnnouncement';
import Gallery from './pages/Gallery';
import AboutPage from './pages/AboutPage';
import Events from './pages/Events';
import StakeHolders from './pages/StakeHolders';
import Support from './pages/Support';
import SplashScreen from './components/SplashScreen';
import PageNotFound from './components/PageNotFound';
import ImageService from './services/ImageService';

function App() {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [initialDelayPassed, setInitialDelayPassed] = useState(false);

  useEffect(() => {
    const minDisplayTimer = setTimeout(() => {
      setInitialDelayPassed(true);
    }, 2500);

    const loadResources = async () => {
      await new Promise(resolve => setTimeout(resolve, 250));
      
      try {
        // eslint-disable-next-line no-unused-vars
        const result = await ImageService.loadAllImages();
        setImagesLoaded(true);
      } catch (error) {
        setImagesLoaded(true);
      }
    };

    loadResources();

    return () => {
      clearTimeout(minDisplayTimer);
    };
  }, []);

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  const showMainApp = imagesLoaded && initialDelayPassed;

  return (
    <div className="App h-full">
      {!showMainApp && <SplashScreen />}

      {showMainApp && (
        <div className="app-content-container">
          <HelmetProvider>
            <BrowserRouter>
              <ScrollToTop />
              <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/gallery' element={<Gallery />} />
                <Route path='/news&announcement' element={<NewsAndAnnouncement />} />
                <Route path='/about/:info' element={<AboutPage />} />
                <Route path='/events' element={<Events />} />
                <Route path='/stakeHolders/:info' element={<StakeHolders />} />
                <Route path='/support/:info' element={<Support />} />
                <Route path='*' element={<PageNotFound />} />
              </Routes>
            </BrowserRouter>
          </HelmetProvider>
        </div>
      )}
    </div>
  );
}

export default App;
