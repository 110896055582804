import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import mentordummy from "../images/dummyface.png";
import ValudPartner from '../Home/Partners';

const Mentors = () => {
  const [mentors, setMentors] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const mentorsPerPage = 9;
  const topRef = useRef(null);

  useEffect(() => {
    const cacheExpiration = 10 * 60 * 1000; // 10 minutes
    const fetchMentors = async () => {
      try {
        const now = new Date().getTime();
        let mentorsData;
        const cachedMentors = localStorage.getItem("mentors");
        
        if (cachedMentors) {
          const { data: cachedData, timestamp } = JSON.parse(cachedMentors);
          if (now - timestamp < cacheExpiration) {
            mentorsData = cachedData;
          }
        }
        
        if (!mentorsData) {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/mentors/`);
          const data = await response.json();
          mentorsData = data.results || data;
          localStorage.setItem("mentors", JSON.stringify({ data: mentorsData, timestamp: now }));
        }
        
        setMentors(mentorsData);
      } catch (error) {
        console.error('Error fetching mentors:', error);
      }
    };
    
    fetchMentors();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setCurrentPage(1);
  };

  // Filter mentors based on search term - memoized
  const filteredMentors = useMemo(() => {
    return mentors.filter(
      (mentor) =>
        mentor.name.toLowerCase().includes(searchTerm) ||
        mentor.role.toLowerCase().includes(searchTerm) ||
        mentor.bio.toLowerCase().includes(searchTerm)
    );
  }, [mentors, searchTerm]);

  // Pagination logic - memoized
  const paginationData = useMemo(() => {
    const totalPages = Math.ceil(filteredMentors.length / mentorsPerPage);
    const indexOfLastMentor = currentPage * mentorsPerPage;
    const indexOfFirstMentor = indexOfLastMentor - mentorsPerPage;
    const currentMentors = filteredMentors.slice(indexOfFirstMentor, indexOfLastMentor);
    
    return {
      totalPages,
      currentMentors
    };
  }, [filteredMentors, currentPage, mentorsPerPage]);

  const nextPage = useCallback(() => {
    if (currentPage < paginationData.totalPages) setCurrentPage(prev => prev + 1);
  }, [currentPage, paginationData.totalPages]);

  const prevPage = useCallback(() => {
    if (currentPage > 1) setCurrentPage(prev => prev - 1);
  }, [currentPage]);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);

  // Memoize the mentor cards to prevent unnecessary re-renders
  const mentorCards = useMemo(() => {
    return paginationData.currentMentors.map((mentor) => (
      <div key={mentor.id} className="bg-white rounded-xl shadow-lg overflow-hidden flex flex-col transform transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
        <div className="relative w-full h-36 bg-gradient-to-r from-blue-100 to-blue-50 flex items-center justify-center">
          <div className="w-28 h-28 rounded-full overflow-hidden border-4 border-white shadow-md">
            <img
              src={mentor.image || mentordummy}
              alt={mentor.name}
              className="w-full h-full object-cover"
              loading="lazy"
            />
          </div>
        </div>
        <div className="p-4 flex flex-col justify-between h-full">
          <div>
            <h3 className="text-lg font-bold text-gray-900 text-center mb-1">{mentor.name}</h3>
            <p className="text-blue-600 font-medium text-center text-sm mb-2">{mentor.role}</p>
            <div className="w-12 h-0.5 bg-blue-500 mx-auto mb-3 rounded-full"></div>
            <p className="text-xs text-gray-600 mt-1 text-center line-clamp-3">{mentor.bio}</p>
          </div>
          <div className="flex justify-center mt-4">
            <a
              href={mentor.link}
              onClick={(e) => {
                if (!mentor.link) {
                  e.preventDefault();
                  alert('Mentor profile link not available');
                }
              }}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gradient-to-r from-blue-600 to-blue-800 text-white text-sm rounded-full px-4 py-1.5 hover:from-blue-700 hover:to-blue-900 transition-all duration-300 shadow-md hover:shadow-lg flex items-center"
            >
              <span>View Profile</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 ml-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    ));
  }, [paginationData.currentMentors]);

  // Memoize the pagination controls
  const paginationControls = useMemo(() => {
    if (paginationData.totalPages <= 1) return null;
    
    return (
      <div className="flex flex-col items-center mt-8 space-y-3">
        <div className="flex items-center space-x-4">
          <button
            onClick={prevPage}
            disabled={currentPage === 1}
            className={`px-4 py-1.5 rounded-full shadow-md transition-all duration-300 flex items-center text-sm ${
              currentPage === 1
                ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                : 'bg-gradient-to-r from-blue-500 to-blue-700 text-white hover:from-blue-600 hover:to-blue-800 hover:shadow-lg'
            }`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            Previous
          </button>
          <div className="flex items-center">
            <span className="text-sm font-medium bg-white px-3 py-1.5 rounded-lg shadow-sm">
              Page {currentPage} of {paginationData.totalPages}
            </span>
          </div>
          <button
            onClick={nextPage}
            disabled={currentPage === paginationData.totalPages}
            className={`px-4 py-1.5 rounded-full shadow-md transition-all duration-300 flex items-center text-sm ${
              currentPage === paginationData.totalPages
                ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                : 'bg-gradient-to-r from-blue-500 to-blue-700 text-white hover:from-blue-600 hover:to-blue-800 hover:shadow-lg'
            }`}
          >
            Next
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    );
  }, [currentPage, paginationData.totalPages, prevPage, nextPage]);

  // Memoize the empty state
  const emptyState = useMemo(() => (
    <div className="w-full py-10 flex flex-col items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-300 mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <p className="text-gray-500 text-base">No mentors found matching your search criteria</p>
      <button 
        onClick={() => setSearchTerm('')}
        className="mt-3 text-blue-600 hover:text-blue-800 font-medium text-sm"
      >
        Clear search
      </button>
    </div>
  ), [setSearchTerm]);

  return (
    <>
      <Helmet>
        <title>Mentors | DFIIE</title>
      </Helmet>
      <div ref={topRef} className="w-full flex flex-col items-center py-6 bg-gradient-to-b from-gray-50 to-gray-200">
        <div className="flex flex-col justify-center items-center py-6 md:px-12 px-6 w-11/12 max-w-7xl">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#192f59] text-center mb-4 sm:mb-6 md:mb-8 relative pb-6">
            <span className="relative inline-block">
              Our Expert Mentors
              <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-1/2 h-1 bg-blue-500 rounded-full"></span>
            </span>
          </h2>
          <p className="text-gray-600 text-center max-w-3xl mb-6 text-sm md:text-base">
            Connect with our distinguished mentors who bring years of industry expertise and knowledge to guide startups and entrepreneurs.
          </p>
          <a
            href="https://forms.gle/Bs7eWnrQEEmh7WMV6"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-gradient-to-r from-blue-600 to-blue-800 text-white text-sm md:text-lg rounded-full px-4 py-2 hover:from-blue-700 hover:to-blue-900 transition-all duration-300 shadow-md hover:shadow-lg mb-6"
          >
            Become a Mentor
          </a>

          {/* Search Bar */}
          <div className="flex flex-col md:flex-row items-center justify-between mb-6 w-full max-w-3xl">
            <div className="relative w-full md:w-2/3">
              <input
                type="text"
                placeholder="Search mentors by name, role or expertise..."
                className="w-full p-2.5 pl-10 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-400 focus:border-blue-400 transition-all duration-300"
                value={searchTerm}
                onChange={handleSearch}
              />
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 absolute left-3 top-3 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
          </div>

          {/* Mentors Grid */}
          {paginationData.currentMentors.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4 w-full">
              {mentorCards}
            </div>
          ) : (
            emptyState
          )}

          {/* Pagination Controls */}
          {paginationControls}
        </div>
      </div>
      <ValudPartner />
    </>
  );
};

export default Mentors;
