import React, { useEffect, useState, useMemo } from "react"; 
import MainLayout from "../layout/Main.layout"; 
import { Helmet } from "react-helmet-async"; 
import axios from "axios"; 
import Image from "../components/Image"; 
import { FaArrowCircleRight } from "react-icons/fa"; 
import loadingImg from "../components/images/Spin.gif"; 

const NewsAndAnnouncement = () => {
  const [newsletters, setNewsletters] = useState([]); 
  const [announcements, setAnnouncements] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cacheExpiration = 10 * 60 * 1000; // 10 minutes expiration
    const fetchData = async () => {
      try {
        const now = new Date().getTime();
        let newslettersData, announcementsData;
        
        // Try to retrieve cached newsletters
        const cachedNewsletters = localStorage.getItem("newsletters");
        if (cachedNewsletters) {
          const { data: cachedNewslettersData, timestamp } = JSON.parse(cachedNewsletters);
          if (now - timestamp < cacheExpiration) {
            newslettersData = cachedNewslettersData;
          }
        }
        
        // Try to retrieve cached announcements
        const cachedAnnouncements = localStorage.getItem("announcements");
        if (cachedAnnouncements) {
          const { data: cachedAnnouncementsData, timestamp } = JSON.parse(cachedAnnouncements);
          if (now - timestamp < cacheExpiration) {
            announcementsData = cachedAnnouncementsData;
          }
        }
        
        // If either data is missing or expired, fetch both concurrently
        if (!newslettersData || !announcementsData) {
          const [newslettersResponse, announcementsResponse] = await Promise.all([
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/newsletters/`),
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/announcement/`)
          ]);
          
          if (!newslettersData) {
            newslettersData = newslettersResponse.data.sort((a, b) => b.id - a.id);
            localStorage.setItem("newsletters", JSON.stringify({ data: newslettersData, timestamp: now }));
          }
          
          if (!announcementsData) {
            announcementsData = announcementsResponse.data.sort((a, b) => b.id - a.id);
            localStorage.setItem("announcements", JSON.stringify({ data: announcementsData, timestamp: now }));
          }
        }
        
        setNewsletters(newslettersData);
        setAnnouncements(announcementsData);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Memoize the announcements section to prevent unnecessary re-renders
  const announcementsSection = useMemo(() => {
    if (!announcements.length) return null;
    
    return (
      <div className="py-6 md:px-12 px-6 rounded-xl lg:w-10/12 w-11/12">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#192f59] text-center mb-4 sm:mb-6 md:mb-8 relative pb-8">
          <span className="relative inline-block">
            Announcements
            <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-1/2 h-1 bg-blue-500 rounded-full "></span>
          </span>
        </h2>

        {/* Announcements Section */}
        <div className="items-start py-2.5 space-y-2 text-xs md:text-base">
          {announcements.map((announcement, index) => (
            <div key={index} className="flex flex-row items-center gap-5 py-2">
              <FaArrowCircleRight className="flex-shrink-0" />
              <a 
                href={announcement.link} 
                target="_blank" 
                rel="noreferrer" 
                className="animate-blinking transition-transform duration-500 ease-in-out hover:scale-105"
              >
                <h2><b>{announcement.announcement}</b></h2>
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }, [announcements]);

  // Memoize the newsletters grid to prevent unnecessary re-renders
  const newslettersGrid = useMemo(() => {
    return (
      <div className="grid sm:grid-cols-3 md:grid-cols-5 gap-4">
        {newsletters.map((item, index) => (
          <div key={index} className="border p-2 shadow rounded bg-white">
            <a href={item.drive_link} target="_blank" rel="noreferrer" style={{ cursor: "pointer" }}>
              <Image
                src={`${item.image}`} 
                className="w-full h-32 rounded-xl"
                alt={item.newslettername}
                loading="lazy"  // Lazy-load the image
              />
              <h1 className="font-semibold text-sm md:text-1xl py-2.5">{item.name}</h1> 
              <p className="text-base md:text-1l rounded-xl">{item.description}</p>
            </a>
          </div>
        ))}
      </div>
    );
  }, [newsletters]);

  return (
    <>
      <Helmet>
        <title>News & Announcement | DFIIE</title>
      </Helmet>

      <div className="w-full h-full flex flex-col justify-center items-center">
        {/* Loading State */}
        {loading && (
          <div className="text-center py-10">
            <img src={loadingImg} alt="Loading..." className="mx-auto mb-4 h-20" />
            <p>Loading announcements and newsletters...</p>
          </div>
        )}

        {/* Error Message */}
        {error && (
          <div className="text-center text-red-600 py-10">
            <p>{error}</p>
          </div>
        )}

        {/* Render memoized announcements section */}
        {!loading && !error && announcementsSection}

        {!loading && !error && (
          <div className="flex flex-col justify-center items-center py-2.5 rounded-xl lg:w-10/12 w-11/12">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#192f59] text-center mb-4 sm:mb-6 md:mb-8 relative pb-8">
              <span className="relative inline-block">
                Newsletters
                <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-1/2 h-1 bg-blue-500 rounded-full "></span>
              </span>
            </h2>

            {/* No Newsletters Message */}
            {newsletters.length === 0 ? (
              <p className="text-center text-gray-600">No newsletters available at the moment.</p>
            ) : (
              /* Render memoized newsletters grid */
              newslettersGrid
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MainLayout(NewsAndAnnouncement);
