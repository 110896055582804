import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { directorData, directorMessage } from '../../Data/About';
import DirectorImage from '../images/directorAboutImg.jpg';
import { motion } from 'framer-motion';
import { FaQuoteLeft } from 'react-icons/fa';

const DirectorMessage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Director's Message | DFIIE</title>
        <meta name="description" content="Message from the Director of DFIIE on innovation, leadership and institutional vision" />
      </Helmet>
      
      <div className="bg-gradient-to-b from-white via-blue-50 to-white py-12 md:py-20">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h1 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-xl md:text-4xl font-bold text-center text-[#192f59] relative tracking-tight mb-6 sm:mb-10"
          >
            <span className="relative inline-block">
              From the Desk of the Director
              <span className="absolute -bottom-3 left-1/2 transform -translate-x-1/2 w-20 md:w-32 h-1 md:h-1.5 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full"></span>
            </span>
          </motion.h1>
          
          <motion.div 
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-6xl mx-auto bg-white rounded-xl sm:rounded-2xl shadow-lg sm:shadow-xl overflow-hidden border border-gray-100"
          >
            {/* Image section with professional styling */}
            <div className="w-full relative">
              <img 
                src={DirectorImage} 
                alt="Director DFIIE" 
                className="w-full h-auto object-cover object-center"
                loading="eager"
              />
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-3 sm:p-4 md:p-6 text-white">
                <h2 className="text-xl sm:text-2xl font-bold">Prof. S. L. Nalbalwar</h2>
                <p className="text-base sm:text-lg opacity-90">Director, DFIIE</p>
              </div>
            </div>
            
            {/* Message content with enhanced styling */}
            <div className="p-4 sm:p-6 md:p-10 lg:p-12">
              <div className="relative mb-6 sm:mb-8">
                <FaQuoteLeft className="text-blue-200 text-4xl sm:text-5xl md:text-6xl absolute -top-2 -left-2 opacity-50" />
                <div className="space-y-3 sm:space-y-5 relative z-10 pl-4 sm:pl-6">
                  {directorMessage.map((message, index) => (
                    <motion.p 
                      key={index} 
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5, delay: 0.2 * index }}
                      className="text-gray-700 text-xs text-justify md:text-lg leading-relaxed"
                    >
                      {message}
                    </motion.p>
                  ))}
                </div>
              </div>
              
              <div className="mt-6 sm:mt-10 pt-6 sm:pt-8 border-t border-gray-100">
                {directorData.map((data, idx) => (
                  <motion.p 
                    key={idx}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4, delay: 0.6 + (idx * 0.1) }}
                    className={`${idx === 0 ? 'text-lg md:text-xl font-bold text-[#192f59] mb-2' : 'text-sm md:text-base md:text-gray-600'} mb-2`}
                  >
                    {data}
                  </motion.p>
                ))}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default DirectorMessage;
