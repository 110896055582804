import React, { useState, useEffect } from 'react';
import '../App.css';
import ImageService from '../services/ImageService';

const SplashScreen = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingStage, setLoadingStage] = useState('Initializing...');

  // Update loading stage based on progress percentage
  useEffect(() => {
    if (loadingProgress === 0) {
      setLoadingStage('Initializing...');
    } else if (loadingProgress <= 10) {
      setLoadingStage('Connecting to servers...');
    } else if (loadingProgress <= 40) {
      setLoadingStage('Loading data from APIs...');
    } else if (loadingProgress < 100) {
      setLoadingStage('Preparing images...');
    } else {
      setLoadingStage('Ready!');
    }
  }, [loadingProgress]);

  useEffect(() => {
    // Set up the progress callback to update loading percentage
    ImageService.setProgressCallback((progressPercent) => {
      setLoadingProgress(progressPercent);
    });
    
    // Return cleanup function
    return () => {
      ImageService.setProgressCallback(null);
    };
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-b from-blue-50 to-white z-50">
      <div className="w-full max-w-md mx-auto px-4 sm:px-6 md:px-8 flex flex-col items-center">
        <div className="mb-6 sm:mb-8 md:mb-10 w-full max-w-xs sm:max-w-sm md:max-w-md mx-auto">
          <img 
            src="/DFIIELogo.png" 
            alt="DFIIE Logo" 
            className="w-full h-auto object-contain max-h-32 sm:max-h-40 md:max-h-48 mx-auto"
            onError={(e) => {
              e.target.onerror = null;
              e.target.style.display = 'none';
              const textLogo = document.createElement('h1');
              textLogo.textContent = 'DFIIE';
              textLogo.className = "text-3xl sm:text-4xl md:text-5xl font-bold text-[#192f59] text-center";
              e.target.parentNode.appendChild(textLogo);
            }}
          />
        </div>

        <div className="w-full mb-6 sm:mb-8">
          <div className="h-2 sm:h-3 bg-gray-200 rounded-full overflow-hidden">
            <div 
              className="h-full bg-gradient-to-r from-blue-500 to-blue-700 rounded-full transition-all duration-300 ease-out"
              style={{ width: `${loadingProgress}%` }}
            ></div>
          </div>
          <div className="mt-2 sm:mt-3 flex flex-col sm:flex-row sm:justify-between items-center">
            <p className="text-sm sm:text-base font-medium text-blue-800">{loadingProgress}% Complete</p>
            <p className="text-xs sm:text-sm text-gray-600 mt-1 sm:mt-0">{loadingStage}</p>
          </div>
        </div>

        <div className="text-center">
          <p className="text-sm sm:text-base md:text-lg text-gray-700 italic">Get ready for an amazing experience...</p>
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;