import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async'; // For managing document head
import axios from 'axios'; // Importing axios for API requests
import dbatuforum from '../images/vision.png'; // Importing vision image
import dbatuforum1 from '../images/mission.jpg'; // Importing mission image
import GalleryHome from '../Home/galleryHome'; // Importing gallery component
import ValuePartners from '../Home/Partners'; // Importing partners component
import ImageSlider from '../ImageSlider'; // Importing image slider component

const VisionAndMission = () => {
  const [vision, setVision] = useState([]); // State to hold vision data
  const [mission, setMission] = useState([]); // State to hold mission data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state for handling fetch errors

  // useEffect to fetch vision and mission data on component mount
  useEffect(() => {
    const fetchAboutUsData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/aboutus/`); // Fetching data from API
        const aboutData = response.data[0]; // Get the first object from the array
        setVision([aboutData.vision]); // Set vision state
        setMission([aboutData.mission]); // Set mission state
      } catch (error) {
        console.error('Error fetching About Us data:', error); // Log any errors
        setError('Failed to load data. Please try again later.'); // Set error message
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    fetchAboutUsData(); // Call the fetch function
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <>
      <Helmet>
        <title>Vision & Mission | DFIIE</title>
      </Helmet>
      <ImageSlider />

      <div className='w-full bg-gradient-to-r from-blue-50 to-indigo-50 py-12'>
        <div className='container mx-auto px-4'>
          <h1 className='text-3xl md:text-4xl font-bold text-center text-[#192f59] mb-10'>
            <span className='relative inline-block'>
              Our Vision & Mission
              <span className='absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-1/2 h-1 bg-blue-500 rounded-full'></span>
            </span>
          </h1>

          {loading ? (
            <div className='flex justify-center items-center py-20'>
              <div className='animate-pulse flex flex-col items-center'>
                <div className='h-12 w-12 mb-4 rounded-full bg-gray-200'></div>
                <p className='text-gray-500'>Loading content...</p>
              </div>
            </div>
          ) : error ? (
            <div className='bg-red-50 border-l-4 border-red-500 p-4 rounded shadow-md'>
              <p className="text-red-700 font-medium">{error}</p>
            </div>
          ) : (
            <div className='space-y-16 max-w-5xl mx-auto'>
              {/* Vision section */}
              <div className='flex md:flex-row flex-col-reverse items-center gap-10 bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300'>
                <div className='md:w-2/3 w-full'>
                  <h2 className='text-2xl md:text-3xl font-semibold mb-4 text-[#192f59] border-b-2 border-blue-200 pb-2'>
                    Vision
                  </h2>
                  {vision.map((data, index) => (
                    <p key={index} className='text-gray-700 leading-relaxed text-justify text-base md:text-lg'>
                      {data}
                    </p>
                  ))}
                </div>
                <div className='md:w-1/3 w-full flex justify-center'>
                  <img 
                    src={dbatuforum} 
                    className='w-full max-w-[300px] object-contain rounded-lg shadow-md hover:shadow-lg transition-all duration-300' 
                    alt="DFIIE Vision" 
                  />
                </div>
              </div>

              {/* Mission section */}
              <div className='flex md:flex-row flex-col-reverse items-center gap-10 bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300'>
                <div className='md:w-2/3 w-full'>
                  <h2 className='text-2xl md:text-3xl font-semibold mb-4 text-[#192f59] border-b-2 border-green-200 pb-2'>
                    Mission
                  </h2>
                  {mission.map((data, index) => (
                    <p key={index} className='text-gray-700 leading-relaxed text-justify text-base md:text-lg'>
                      {data}
                    </p>
                  ))}
                </div>
                <div className='md:w-1/3 w-full flex justify-center'>
                  <img 
                    src={dbatuforum1} 
                    className='w-full max-w-[300px] object-contain rounded-lg shadow-md hover:shadow-lg transition-all duration-300' 
                    alt="DFIIE Mission" 
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ValuePartners />
      <GalleryHome />
    </>
  );
};

export default VisionAndMission;
