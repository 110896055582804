import React, { useState } from 'react'; // Import React and hooks
import { AiFillPhone } from 'react-icons/ai'; // Import phone icon
import { MdEmail } from 'react-icons/md'; // Import email icon
import { IoLocation } from 'react-icons/io5'; // Import location icon
import MainLayout from '../layout/Main.layout'; // Import the main layout component
import SkeletonImage from '../components/SkeletonImage'; // Import skeleton loader for images
import { Helmet } from 'react-helmet-async'; // Import Helmet for managing the document head
import contact from '../components/images/contactus.jpg'; // Import contact image
import axios from 'axios'; // Import axios for API calls

const Contact = () => {
  // State variables for form inputs and messages
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true); // State for image loading
  const [error, setError] = useState(""); // State for error messages
  const [success, setSuccess] = useState(""); // State for success messages

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    try {
      // Send form data to the server
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/contactus/`, {
        name,
        email,
        message,
      });
      // Clear form fields
      setName('');
      setEmail('');
      setMessage('');
      setSuccess('Your message has been sent successfully!'); // Success message
      setError(''); // Clear any previous error messages
    } catch (error) {
      console.error('Error sending message:', error); // Log error
      setError('Failed to send message. Please try again later.'); // Set error message
      setSuccess(''); // Clear any previous success messages
    }
  };

  return (
    <div className="bg-gray-50">
      <Helmet>
        <title>Contact | DFIIE</title>
      </Helmet>
      
      {/* Hero Banner */}
      <div className="relative">
        <img
          className={`w-full h-80 object-cover object-center ${loading ? 'hidden' : 'block'}`}
          src={contact}
          alt="Contact DFIIE"
          onLoad={() => setLoading(false)}
          onError={() => setLoading(true)}
        />
        {loading && <SkeletonImage height={'24rem'} width={'100%'} />}
        
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/70 to-black/50 flex items-center justify-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white tracking-wide">
            Get In Touch
          </h1>
        </div>
      </div>

      {/* Contact Form Section */}
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            <span className="block">Contact Us</span>
          </h2>
          <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
            Feel free to reach out with any questions or inquiries. Our team is here to help you navigate your innovation journey.
          </p>
          
          {/* Alert Messages */}
          {success && (
            <div className="mt-4 p-4 bg-green-100 border-l-4 border-green-500 text-green-700 rounded">
              <p className="font-medium">{success}</p>
            </div>
          )}
          
          {error && (
            <div className="mt-4 p-4 bg-red-100 border-l-4 border-red-500 text-red-700 rounded">
              <p className="font-medium">{error}</p>
            </div>
          )}
        </div>

        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="md:flex">
            {/* Contact Form */}
            <div className="md:w-2/3 p-8">
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-3 px-4 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Your name"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-3 px-4 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="you@example.com"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                    Message
                  </label>
                  <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows="6"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-3 px-4 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="How can we help you?"
                    required
                  ></textarea>
                </div>
                
                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-blue-800 hover:from-blue-700 hover:to-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-300"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
            
            {/* Contact Information */}
            <div className="md:w-1/3 bg-gradient-to-br from-blue-800 to-blue-900 p-8 text-white">
              <h3 className="text-xl font-bold mb-8">Contact Information</h3>
              
              <div className="space-y-6">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <AiFillPhone className="h-6 w-6 text-blue-200" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-blue-100">Phone</p>
                    <p className="text-base mt-1">+91 8275093218</p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <MdEmail className="h-6 w-6 text-blue-200" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-blue-100">Email</p>
                    <p className="text-base mt-1">ceo.dfiie@dbatu.ac.in</p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <IoLocation className="h-6 w-6 text-blue-200" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-blue-100">Address</p>
                    <p className="text-base mt-1">
                      DBATU Forum of Innovation, Incubation & Enterprise (DFIIE)<br />
                      Dr. Babasaheb Ambedkar Technological University,<br />
                      Lonere, Tal- Mangaon, Dist – Raigad,<br />
                      Maharashtra (India) - 402103
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Map Section */}
      <div className="bg-gray-100 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-gray-900">Find Us</h2>
            <p className="mt-2 text-gray-600">Visit our campus in Lonere, Maharashtra</p>
          </div>
          
          <div className="rounded-lg overflow-hidden shadow-lg">
            <iframe 
              className="w-full h-96" 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d857.6345717000443!2d73.33845126538297!3d18.169984133544318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be82e9b091399f5%3A0x2fac4343fa1e3cae!2sDr.%20Babasaheb%20Ambedkar%20Technological%20University!5e1!3m2!1sen!2sin!4v1730314048525!5m2!1sen!2sin" 
              allowFullScreen="" 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade" 
              title="Google Map of Dr. Babasaheb Ambedkar Technological University">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

// Export the Contact component wrapped in the MainLayout HOC
export default MainLayout(Contact);
