import React, { useEffect, useState, useMemo } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import './style.css';
import axios from 'axios';
import { Autoplay, Pagination } from "swiper";
import SkeletonImage from '../components/SkeletonImage';

export default function ImageSlider() {
  const [posterImages, setPosterImages] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const cacheExpiration = 10 * 60 * 1000; // 10 minutes
  
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/home-banners/`);
        const images = response.data.map(banner => banner.image);
        
        if (images.length > 0) {
          setPosterImages(images);
          const cacheData = { images, timestamp: new Date().getTime() };
          localStorage.setItem("homeBanners", JSON.stringify(cacheData));
          setLoading(false);
        } else {
          throw new Error("No images found");
        }
      } catch (error) {
        console.error('Error fetching home banners:', error);
        setTimeout(fetchImages, 3000);
      }
    };
  
    const cached = localStorage.getItem("homeBanners");
    if (cached) {
      const { images, timestamp } = JSON.parse(cached);
      if (new Date().getTime() - timestamp < cacheExpiration) {
        setPosterImages(images);
        setLoading(false);
      } else {
        localStorage.removeItem("homeBanners");
        fetchImages();
      }
    } else {
      fetchImages();
    }
  }, []);
  
  // Memoize the banner images to prevent unnecessary re-renders
  const memoizedBanners = useMemo(() => {
    return posterImages.map((image, index) => (
      <SwiperSlide key={index}>
        <img src={image} alt={`Banner ${index + 1}`} loading="lazy" />
      </SwiperSlide>
    ));
  }, [posterImages]);

  // Memoize the skeleton loaders
  const memoizedSkeletons = useMemo(() => {
    return [...Array(6)].map((_, index) => (
      <SkeletonImage key={index} height={'4rem'} width={'99rem'} />
    ));
  }, []);

  return (
    <>
      {loading ? (
        memoizedSkeletons
      ) : (
        <Swiper
          direction={"horizontal"}
          pagination={{ clickable: true }}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          loop={true}
          speed={800}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {memoizedBanners}
        </Swiper>
      )}
    </>
  );
}
