import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import ValudPartner from '../Home/Partners';
import dummy from "../images/dummyface.png";

const StartUps = () => {
  const [startUps, setStartUps] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const startupsPerPage = 9;
  const topRef = useRef(null);

  // Fetch StartUps from the Django API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/startup`)
      .then((response) => response.json())
      .then((data) => {
        // Sort StartUps by priority (highest priority first)
        const sortedStartUps = data.sort((a, b) => b.priority - a.priority);
        setStartUps(sortedStartUps);
        setShowFullDescription(new Array(sortedStartUps.length).fill(false));
      })
      .catch((error) => console.error('Error fetching startup:', error));
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setCurrentPage(1); // Reset to first page when a new search is performed
  };

  // Filter startups based on search term - memoized
  const filteredStartUps = useMemo(() => {
    return startUps.filter(
      (startUp) =>
        startUp.name.toLowerCase().includes(searchTerm) ||
        startUp.sector.toLowerCase().includes(searchTerm) ||
        startUp.description.toLowerCase().includes(searchTerm)
    );
  }, [startUps, searchTerm]);

  // Calculate pagination values - memoized
  const paginationData = useMemo(() => {
    const totalPages = Math.ceil(filteredStartUps.length / startupsPerPage);
    const indexOfLastStartup = currentPage * startupsPerPage;
    const indexOfFirstStartup = indexOfLastStartup - startupsPerPage;
    const currentStartUps = filteredStartUps.slice(indexOfFirstStartup, indexOfLastStartup);
    
    return {
      totalPages,
      currentStartUps,
      indexOfFirstStartup
    };
  }, [filteredStartUps, currentPage, startupsPerPage]);

  const nextPage = useCallback(() => {
    if (currentPage < paginationData.totalPages) {
      setCurrentPage(prev => prev + 1);
    }
  }, [currentPage, paginationData.totalPages]);

  const prevPage = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  }, [currentPage]);

  const toggleDescription = useCallback((globalIndex) => {
    setShowFullDescription(prev => {
      const newShowFullDescription = [...prev];
      newShowFullDescription[globalIndex] = !newShowFullDescription[globalIndex];
      return newShowFullDescription;
    });
  }, []);

  // Scroll to the top of the container when currentPage changes
  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);

  // Memoize the startup cards to prevent unnecessary re-renders
  const startupCards = useMemo(() => {
    return paginationData.currentStartUps.map((startUp, index) => {
      // Calculate the global index in the startUps array.
      const globalIndex = paginationData.indexOfFirstStartup + index;
      return (
        <div key={startUp.id} className="bg-white rounded-xl shadow-lg overflow-hidden flex flex-col transform transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
          {/* StartUp Image */}
          <div className="w-full h-36 bg-gradient-to-r from-blue-50 to-gray-50 flex items-center justify-center p-4">
            <div className="w-28 h-28 flex items-center justify-center">
              <img
                src={startUp.image || dummy}
                alt={startUp.name}
                className="max-w-full max-h-full object-contain"
                loading="lazy"
              />
            </div>
          </div>
          <div className="p-4 flex flex-col justify-between h-full">
            {/* StartUp Info */}
            <div>
              <h3 className="text-lg font-bold text-gray-900 text-center mb-1">{startUp.name}</h3>
              <p className="text-blue-600 font-medium text-center text-sm mb-2">{startUp.sector}</p>
              <div className="w-12 h-0.5 bg-blue-500 mx-auto mb-3 rounded-full"></div>
              <div className="mt-2">
                <p className="text-sm text-gray-600 text-justify">
                  {showFullDescription[globalIndex]
                    ? startUp.description
                    : startUp.description.length > 100
                      ? `${startUp.description.substring(0, 100)}...`
                      : startUp.description}
                </p>
                {startUp.description.length > 100 && (
                  <button
                    onClick={() => toggleDescription(globalIndex)}
                    className="text-sm text-blue-600 hover:text-blue-800 font-medium mt-1 transition-colors duration-200"
                  >
                    {showFullDescription[globalIndex] ? 'Show less' : 'Read more'}
                  </button>
                )}
              </div>
            </div>
            {/* Action Button */}
            <div className="flex justify-center mt-4">
              <a
                href={startUp.link}
                onClick={(e) => {
                  if (!startUp.link) {
                    e.preventDefault();
                    alert('Profile not Available');
                  }
                }}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-gradient-to-r from-blue-600 to-blue-800 text-white text-sm rounded-full px-4 py-1.5 hover:from-blue-700 hover:to-blue-900 transition-all duration-300 shadow-md hover:shadow-lg flex items-center"
              >
                <span>Know More</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 ml-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      );
    });
  }, [paginationData.currentStartUps, paginationData.indexOfFirstStartup, showFullDescription, toggleDescription]);

  // Memoize the no results component
  const noResultsComponent = useMemo(() => (
    <div className="w-full py-10 flex flex-col items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-300 mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <p className="text-gray-500 text-base">No startups found matching your search criteria</p>
      <button 
        onClick={() => setSearchTerm('')}
        className="mt-3 text-blue-600 hover:text-blue-800 font-medium text-sm"
      >
        Clear search
      </button>
    </div>
  ), []);

  // Memoize pagination controls
  const paginationControls = useMemo(() => {
    if (paginationData.totalPages <= 1) return null;
    
    return (
      <div className="flex flex-col items-center mt-8 space-y-3">
        <div className="flex items-center space-x-4">
          <button
            onClick={prevPage}
            disabled={currentPage === 1}
            className={`px-4 py-1.5 rounded-full shadow-md transition-all duration-300 flex items-center text-sm ${
              currentPage === 1
                ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                : 'bg-gradient-to-r from-blue-500 to-blue-700 text-white hover:from-blue-600 hover:to-blue-800 hover:shadow-lg'
            }`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            Previous
          </button>
          <div className="flex items-center">
            <span className="text-sm font-medium bg-white px-3 py-1.5 rounded-lg shadow-sm">
              Page {currentPage} of {paginationData.totalPages}
            </span>
          </div>
          <button
            onClick={nextPage}
            disabled={currentPage === paginationData.totalPages}
            className={`px-4 py-1.5 rounded-full shadow-md transition-all duration-300 flex items-center text-sm ${
              currentPage === paginationData.totalPages
                ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                : 'bg-gradient-to-r from-blue-500 to-blue-700 text-white hover:from-blue-600 hover:to-blue-800 hover:shadow-lg'
            }`}
          >
            Next
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    );
  }, [currentPage, paginationData.totalPages, prevPage, nextPage]);

  return (
    <>
      <Helmet>
        <title>StartUps | DFIIE</title>
      </Helmet>
      <div ref={topRef} className="w-full flex flex-col items-center py-8 bg-gradient-to-b from-gray-50 to-gray-200">
        <div className="flex flex-col justify-center items-center py-6 md:px-12 px-6 w-11/12 max-w-7xl">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#192f59] text-center mb-4 sm:mb-6 md:mb-8 relative pb-6">
            <span className="relative inline-block">
              Incubated StartUps
              <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-1/2 h-1 bg-blue-500 rounded-full"></span>
            </span>
          </h2>
          <p className="text-gray-600 text-center max-w-3xl mb-6 text-sm md:text-base">
            Discover innovative startups nurtured and supported by our incubation program, driving technological advancement and entrepreneurial growth.
          </p>

          {/* Search Bar */}
          <div className="flex flex-col md:flex-row items-center justify-between mb-6 w-full max-w-3xl">
            <div className="relative w-full md:w-2/3">
              <input
                type="text"
                placeholder="Search startups by name, sector or description..."
                className="w-full p-2.5 pl-10 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-400 focus:border-blue-400 transition-all duration-300"
                value={searchTerm}
                onChange={handleSearch}
              />
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 absolute left-3 top-3 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
          </div>

          {/* StartUps Grid */}
          {paginationData.currentStartUps.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4 w-full">
              {startupCards}
            </div>
          ) : (
            noResultsComponent
          )}

          {/* Pagination Controls */}
          {paginationControls}
        </div>
      </div>
      <ValudPartner />
    </>
  );
};

export default StartUps;
